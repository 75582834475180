import React, {
  useState,
  useMemo,
  useEffect,
  useRef,
  useCallback,
} from 'react';
import Box from '@mui/material/Box';
import Lottie from 'lottie-react';
import Typography from '@mui/material/Typography';
import {
  WorkflowCard,
  ASSESSMENT_STATUS,
  WAITING_TIME_TEXT,
  STAGE_STATUS,
} from '../../components/WorkflowCard';
import {
  fireNetworkRequest,
  useAuthenticatedMutation,
  useAuthenticatedQuery,
} from '../../network';
import Fab from '@mui/material/Fab';
import ChatIcon from '@mui/icons-material/Chat';
import partyPopperImage from '../../assets/partyPopperBadge.png';
import EmptyWorkflow from './EmptyWorkflow';
import DataLoading from './DataLoading';
import { Logger, Support } from '../../services';
import { Assessment, Toast } from '../../components';
import confetti_animation from '../../assets/confetti_animation.json';
import sadFaceBadge from '../../assets/sadFaceBadge.png';
import { STORAGE_KEYS } from '../../constants';
import findLastIndex from 'lodash.findlastindex';
import { useNetworkSpeed } from '../../hooks';
import money3D from '../../assets/3dMoney.webp';
import { TechnicalErrorDialog } from '../../components/TechnicalErrorDialog';

const mixpanel_project_token =
  process.env.REACT_APP_ENV === 'production'
    ? '558c38c56c909416b9c12e0cd9b72a42'
    : '37db8142d0efcb84e4bc46c6f14d0cee';

const HeaderText = ({ isCompleted, isFailed, heading, subHeading }) => {
  const headerConfig = useMemo(() => {
    let headerData = {
      title: 'Welcome to SquadStack Partners!',
      subtitle:
        'Complete the steps below to kickstart your journey as a Sales Expert',
      img: money3D,
    };
    if (isCompleted) {
      headerData = {
        title: 'Congratulations! You’re all set!',
        subtitle:
          'You are all set to become a Sales Expert and start working anytime, anywhere.',
        img: partyPopperImage,
      };
    } else if (isFailed) {
      headerData = {
        title: 'Oops, you could not pass the assessment!',
        subtitle:
          'Sorry, you could not pass this assessment. We will let you know when you can try again.',
        img: sadFaceBadge,
      };
    }
    return headerData;
  }, [isCompleted, isFailed]);

  return (
    <Box
      display='flex'
      flexDirection='row'
      alignItems='center'
      justifyContent='space-between'
      marginTop='40px'
      backgroundColor='tint.lavender700'
      borderRadius={2}
      width='100%'
      paddingX={{ xs: 2, sm: 4 }}
      paddingY={2}>
      <Box display='flex' flexDirection='column'>
        <Box display='flex' flexDirection='row' alignItems='center'>
          <Box display={{ xs: 'flex', sm: 'none' }}>
            <img
              src={headerConfig.img}
              height={48}
              style={{ marginRight: '24px' }}
            />
          </Box>
          <Typography
            sx={{
              fontWeight: '700',
              fontSize: 28,
              lineHeight: '32px',
              color: 'common.white',
              textAlign: 'left',
            }}>
            {heading || headerConfig.title}
          </Typography>
        </Box>
        <Typography
          sx={{
            fontWeight: '400',
            fontSize: 20,
            lineHeight: '24px',
            color: 'common.white',
            textAlign: 'left',
            marginTop: '8px',
          }}>
          {subHeading || headerConfig.subtitle}
        </Typography>
      </Box>
      <Box
        display={{ xs: 'none', sm: 'flex' }}
        flexDirection='row'
        alignItems='center'
        marginLeft={3}>
        <img src={headerConfig.img} width='100px' height='100px' />
      </Box>
    </Box>
  );
};

const Workflow = ({ navHeight }) => {
  useEffect(() => {
    const count = localStorage.getItem(STORAGE_KEYS.COUNT);
    const assessmentName = localStorage.getItem(STORAGE_KEYS.ASSESSMENT_NAME);
    const userDetails = localStorage.getItem(STORAGE_KEYS.USER_DETAILS);
    const parsedUserDetails = userDetails && JSON.parse(userDetails);
    if (count && parsedUserDetails?.userId && assessmentName) {
      const data = JSON.stringify({
        properties: {
          token: mixpanel_project_token,
          distinct_id: parsedUserDetails?.userId,
          assessmentName,
          count,
        },
        event: 'SE Reload',
      });
      const options = {
        method: 'POST',
        mode: 'cors',
        credentials: 'omit',
        cache: 'no-store',
        redirect: 'follow',
        body: new URLSearchParams({ data }).toString(),
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      };

      fetch('https://api.mixpanel.com/track', options)
        .then(() => {
          localStorage.removeItem(STORAGE_KEYS.COUNT);
          localStorage.removeItem(STORAGE_KEYS.ASSESSMENT_NAME);
        })
        .catch(() => {
          // do nothing
        });
    }
  }, []);

  const lottieRef = useRef(null);

  const [isWorkflowCompleted, setIsWorkflowCompleted] = useState(false);

  const { toastState, checkNetworkSpeed, onClose } = useNetworkSpeed();

  const { refetch: workflowQueryRefetch, data: workflowQueryData } =
    useAuthenticatedQuery(
      ['workflow-query'],
      ({ signal }) =>
        fireNetworkRequest(
          {
            url: 'tof/api/player/stages/',
            method: 'get',
          },
          { isAuthenticated: true },
          signal
        ),
      {
        onSuccess: (data) => {
          Logger.info(
            isDataAvailable
              ? 'Workflow Fetched Successfully'
              : 'Workflow Data Unavailable',
            {
              data: data?.data,
            }
          );
        },
        onError: (e) => {
          Logger.error(new Error('Workflow Fetch Failed'), {
            e,
          });
        },
        refetchInterval: (data) => {
          if (!data?.data?.is_data_available) {
            return 2500;
          }
          if (data?.data?.is_completed || data?.data?.is_failed)
            return undefined;
          return 7000;
        },
      }
    );

  const isDataAvailable = useMemo(() => {
    return (
      Array.isArray(workflowQueryData?.data?.stages) &&
      workflowQueryData?.data?.stages?.length !== 0
    );
  }, [workflowQueryData?.data?.stages]);

  const updateWorkflowStageStatus = useAuthenticatedMutation(
    ({ assessment_id }) => {
      return fireNetworkRequest(
        {
          url: `assessments/api/v10/assessment/mark-in-progress/${assessment_id}/`,
          method: 'patch',
        },
        { isAuthenticated: true }
      );
    },
    {
      onSuccess: (data) => {
        Logger.info('Workflow Stage Marked In-Progress Successfully', {
          url: data.config.url,
        });
        workflowQueryRefetch();
      },
      onError: (e) => {
        Logger.error(new Error(`Workflow Stage Mark In-Progress Failed`), {
          e,
        });
      },
    }
  );

  useAuthenticatedMutation(
    ({ assessment_id }) => {
      return fireNetworkRequest(
        {
          url: `assessments/api/v10/${assessment_id}/`,
          method: 'patch',
          data: {
            test_status: 'submitted',
          },
        },
        { isAuthenticated: true }
      );
    },
    {
      onSuccess: (data) => {
        Logger.info('Workflow Stage Marked In-Review Successfully', {
          data: data?.data,
        });
      },
    }
  );

  const [, setIsWorkflowInProgress] = useState(false);

  const [lastActiveAssessment, setLastActiveAssessment] = useState(null);
  const [lastSubmittedAssessment, setLastSubmittedAssessment] = useState(null);
  const [inProgressAssessment, setInProgressAssessment] = useState(null);

  useEffect(() => {
    checkNetworkSpeed();
  }, [checkNetworkSpeed]);

  const handleWorkflowCardClick = (workflowCardData, index) => {
    checkNetworkSpeed();
    const { id, redirect_url } = workflowCardData;

    Logger.info('Workflow Card Clicked', workflowCardData);

    if (id) {
      if (id === lastSubmittedAssessment?.id) {
        localStorage.removeItem(STORAGE_KEYS.LAST_STAGE_SUBMITTED);
      }
      updateWorkflowStageStatus.mutate({ assessment_id: id });
      setIsWorkflowInProgress(true);
    }

    if (
      redirect_url &&
      !redirect_url.includes('com.squad.run') &&
      !redirect_url.includes('kyc.idfy.com')
    ) {
      setInProgressAssessment({ ...workflowCardData, index });
    } else {
      window.open(
        redirect_url,
        redirect_url.includes('kyc.idfy.com') ? '_self' : '_blank',
        'noopener,noreferrer'
      );
    }
  };

  const handleAssessmentSubmission = (id) => {
    localStorage.setItem(
      STORAGE_KEYS.LAST_STAGE_SUBMITTED,
      JSON.stringify({ id: id, timestamp: Date.now() })
    );
    setLastSubmittedAssessment({ id: id, timestamp: Date.now() });

    //Disabling In-review API for now.
    /* updateReviewWorkflowStageStatus.mutate({ assessment_id: id }); */
    setInProgressAssessment(null);

    window.postMessage(`${id}_submitted`, '*');
    Logger.info('Assessment Window Closed', {
      stages: workflowQueryData?.data?.stages,
      id: id,
    });
  };

  useEffect(() => {
    setLastSubmittedAssessment(
      JSON.parse(localStorage.getItem(STORAGE_KEYS.LAST_STAGE_SUBMITTED))
    );
  }, []);

  const timeTakenForNewStageToUnlock = useCallback((workflowData) => {
    const lastSubmittedAssessment = JSON.parse(
      localStorage.getItem(STORAGE_KEYS.LAST_STAGE_SUBMITTED)
    );

    const lastSubmittedAssessmentIndex = workflowData?.findIndex(
      (e) => e.id === lastSubmittedAssessment?.id
    );

    const nextAssessmentIndex = lastSubmittedAssessmentIndex + 1;

    if (
      workflowData[nextAssessmentIndex]?.status === STAGE_STATUS.ACTIVE &&
      lastSubmittedAssessment
    ) {
      Logger.info('Next Stage Unlocked', {
        lastSubmittedAssessment: lastSubmittedAssessment,
        nextAssessment: workflowData[nextAssessmentIndex],
        timeTaken: (Date.now() - lastSubmittedAssessment?.timestamp) / 1000,
      });

      localStorage.removeItem(STORAGE_KEYS.LAST_STAGE_SUBMITTED);
    }

    if (
      lastSubmittedAssessmentIndex === -1 ||
      (workflowData[lastSubmittedAssessmentIndex]?.status ===
        STAGE_STATUS.ACTIVE &&
        !workflowData[lastSubmittedAssessmentIndex]?.assessment_status)
    ) {
      localStorage.removeItem(STORAGE_KEYS.LAST_STAGE_SUBMITTED);

      setLastSubmittedAssessment(null);
    }
  }, []);

  useEffect(() => {
    if (isDataAvailable) {
      timeTakenForNewStageToUnlock(workflowQueryData?.data?.stages);
    }
  }, [
    isDataAvailable,
    timeTakenForNewStageToUnlock,
    workflowQueryData?.data?.stages,
  ]);

  const hasActiveAssessments = useCallback(
    (workflowData) => {
      if (isDataAvailable) {
        const hasActiveStages = workflowData?.some(
          (e) =>
            (e.status === STAGE_STATUS.ACTIVE ||
              e.status === STAGE_STATUS.HOLD) &&
            (e.assessment_status === ASSESSMENT_STATUS.IN_PROGRESS ||
              e.assessment_status === ASSESSMENT_STATUS.IN_REVIEW ||
              e.assessment_status === ASSESSMENT_STATUS.FAILED ||
              e.assessment_status === ASSESSMENT_STATUS.REATTEMPT ||
              e.assessment_status === null)
        );

        if (!hasActiveStages) {
          const lastPassedStageIndex = findLastIndex(
            workflowData,
            (e) => e.assessment_status === ASSESSMENT_STATUS.PASSED
          );
          Logger.info('User has no active assessments', {
            stages: workflowData,
          });
          const isLastStage = lastPassedStageIndex === workflowData.length - 1;

          setLastActiveAssessment({
            ...workflowData[lastPassedStageIndex],
            metadata: isLastStage
              ? workflowData[lastPassedStageIndex].metadata
              : WAITING_TIME_TEXT.IN_PASSED,
          });
        } else {
          setLastActiveAssessment(null);
        }
      }
    },
    [isDataAvailable]
  );

  useEffect(() => {
    if (
      !workflowQueryData?.data?.is_completed &&
      !workflowQueryData?.data?.is_failed
    )
      hasActiveAssessments(workflowQueryData?.data?.stages);
  }, [
    hasActiveAssessments,
    workflowQueryData?.data?.is_completed,
    workflowQueryData?.data?.is_failed,
    workflowQueryData?.data?.stages,
  ]);

  useEffect(() => {
    setIsWorkflowInProgress(
      Boolean(
        Array.isArray(workflowQueryData?.data?.stages) &&
          workflowQueryData?.data?.stages?.some(
            (e) =>
              e.assessment_status === ASSESSMENT_STATUS.IN_PROGRESS ||
              e.assessment_status === ASSESSMENT_STATUS.IN_REVIEW
          )
      )
    );
    setIsWorkflowCompleted(workflowQueryData?.data?.is_completed);
  }, [workflowQueryData?.data?.is_completed, workflowQueryData?.data?.stages]);

  useEffect(() => {
    if (isWorkflowCompleted) {
      Logger.info('Workflow Completed', {
        stages: workflowQueryData?.data?.stages,
      });
      window.postMessage('workflow_completed', '*');
      setTimeout(() => {
        lottieRef?.current?.play();
      }, 1000);
    }
  }, [isWorkflowCompleted, workflowQueryData?.data?.stages]);

  const openSupport = () => {
    Support.open();
    Logger.info('Support Button Clicked');
  };

  if (!workflowQueryData?.data?.is_data_available) {
    return <DataLoading />;
  }

  const isTechnicalIssue = Boolean(workflowQueryData?.data?.tech_issue_message);

  if (isTechnicalIssue) {
    return (
      <TechnicalErrorDialog msg={workflowQueryData?.data?.tech_issue_message} />
    );
  }

  return (
    <>
      <Toast
        isVisible={toastState.isVisible}
        autoHideDuration={toastState.autoHideDuration}
        severity={toastState.severity}
        msg={toastState.msg}
        onClose={onClose}
        isPersistent={toastState.isPersistent}
      />
      {inProgressAssessment ? (
        <Box
          data-testid='assessment-wrapper'
          position='fixed'
          width='100%'
          display='flex'
          top={navHeight}
          bottom={0}>
          <Assessment
            assessmentId={inProgressAssessment?.id}
            src={inProgressAssessment?.redirect_url}
            title={inProgressAssessment?.name}
            index={inProgressAssessment?.index}
            handleAssessmentSubmission={handleAssessmentSubmission}
          />
        </Box>
      ) : (
        <>
          {isWorkflowCompleted ? (
            <Lottie
              lottieRef={lottieRef}
              loop={false}
              autoplay={false}
              animationData={confetti_animation}
              style={{
                position: 'absolute',
                margin: 'auto',
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                width: '100%',
                zIndex: 90,
              }}
            />
          ) : null}

          <Fab
            color='primary'
            sx={{
              position: 'fixed',
              bottom: 16,
              right: 16,
              display: 'flex',
              flexDirection: 'column',
              textTransform: 'capitalize',
              padding: 4,
            }}
            onClick={() => openSupport()}>
            <ChatIcon />
            Help
          </Fab>

          <Box
            position={'relative'}
            height='100%'
            width='100%'
            display='flex'
            flexDirection='column'
            alignItems='center'
            justifyContent='space-between'
            padding={2}
            paddingTop='32px'>
            {Array.isArray(workflowQueryData?.data?.stages) &&
            workflowQueryData?.data?.stages?.length !== 0 ? (
              <>
                <Box
                  position='relative'
                  display='flex'
                  flexDirection='column'
                  alignItems='center'
                  justifyContent='center'
                  maxWidth={568}>
                  <HeaderText
                    isCompleted={workflowQueryData?.data?.is_completed}
                    isFailed={workflowQueryData?.data?.is_failed}
                    heading={workflowQueryData?.data?.heading}
                    subHeading={workflowQueryData?.data?.sub_heading}
                  />
                  <Box marginTop={4} width='100%'>
                    {workflowQueryData?.data?.stages?.map((c, i) => {
                      return (
                        <WorkflowCard
                          testId={`workflow-card-${i}`}
                          key={`${i}_${c.id}`}
                          title={c.name}
                          order={i + 1}
                          data={c}
                          handleOnClick={handleWorkflowCardClick}
                          isWorkflowFailed={workflowQueryData?.data?.is_failed}
                          lastActiveAssessment={lastActiveAssessment}
                          lastSubmittedAssessment={lastSubmittedAssessment}
                          isLastStage={
                            i === workflowQueryData?.data?.stages?.length - 1
                          }
                        />
                      );
                    })}
                  </Box>
                </Box>
                <Box
                  sx={{
                    marginTop: 3,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}>
                  <Typography
                    variant='subtitle2'
                    sx={{
                      fontSize: 14,
                      lineHeight: '32px',
                      color: 'text.secondary',
                      textAlign: 'center',
                      marginTop: 3,
                    }}>
                    🔐 All of your data is encrypted & secure
                  </Typography>
                </Box>
              </>
            ) : (
              <EmptyWorkflow />
            )}
          </Box>
        </>
      )}
    </>
  );
};

export default Workflow;
