export const STAGE_STATUS = {
  HOLD: 'hold',
  LOCKED: 'locked',
  ACTIVE: 'active',
  COMPLETED: 'completed',
};

export const ASSESSMENT_STATUS = {
  PASSED: 'passed',
  FAILED: 'failed',
  IN_PROGRESS: 'in_progress',
  IN_REVIEW: 'in_review',
  REATTEMPT: 're_attempt',
};

export const WAITING_TIME_TEXT = {
  IN_PASSED: 'Preparing your next steps now. Expected wait time: <1 minute.',
  IN_REVIEW: 'We are evaluating your result. Wait time less than 1 min.',
};
