import mixpanelBrowser from 'mixpanel-browser';

const isProd = process.env.REACT_APP_ENV === 'production';
class Mixpanel {
  init = false;
  constructor() {
    if (
      process.env.REACT_APP_MIXPANEL &&
      process.env.REACT_APP_ENABLE_MXP === 'true'
    ) {
      this.initModule();
    }
  }

  initModule() {
    mixpanelBrowser.init(process.env.REACT_APP_MIXPANEL, {
      debug: !isProd,
      ignore_dnt: true,
    });
    this.init = true;
  }

  disableModule() {
    if (this.isInitialised()) {
      mixpanelBrowser.disable();
      this.init = false;
    }
  }

  isInitialised() {
    return this.init;
  }

  trackEvent(eventName, extra) {
    if (this.isInitialised()) {
      mixpanelBrowser.track(eventName, extra);
    }
  }

  trackLinks(redirectLink) {
    if (this.isInitialised()) {
      mixpanelBrowser.track_links('#nav a', 'Workflow Link Clicked', {
        referrer: document.referrer,
        redirectLink,
      });
    }
  }

  identify(userDetails) {
    if (this.isInitialised()) {
      const { id, email_id, first_name, last_name, profile_pic, source } =
        userDetails;
      mixpanelBrowser.identify(id);
      mixpanelBrowser.people.set({
        $name: `${first_name} ${last_name}`.trim(),
        $firstName: first_name,
        $lastName: last_name,
        $email: email_id,
        $avatar: profile_pic,
      });
      mixpanelBrowser.register({ source: source });
    }
  }
}

const mixpanel = new Mixpanel();
export default mixpanel;
