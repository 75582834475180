import React, { useState, useEffect } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { STORAGE_KEYS } from '../../constants';

function isIOSDevice() {
  return (
    /iPad|iPhone|iPod/.test(navigator.platform) ||
    navigator.platform === 'MacIntel'
  );
}

const IOSDeviceDialog = () => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(isIOSDevice() && !localStorage.getItem(STORAGE_KEYS.IOS_DIALOG));
  }, []);

  const onContinue = () => {
    localStorage.setItem(STORAGE_KEYS.IOS_DIALOG, true);
    setOpen(false);
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      aria-labelledby='responsive-dialog-title'>
      <DialogTitle id='responsive-dialog-title'>
        {'Apple Device Detected'}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {`We’ve detected that you are using an Apple device, our app is
          currently only supported on android devices.`}
        </DialogContentText>
        <DialogContentText marginTop={1}>
          {` Please click on "Continue" if you have a working android device.`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onContinue}>Continue</Button>
      </DialogActions>
    </Dialog>
  );
};

export default IOSDeviceDialog;
