import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import Grid from '@mui/material/Grid';
import { ReactComponent as SquadStackPartnersLogo } from '../../assets/squadStackPartnersLogo-full-dark.svg';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';

function FacebookLoginView({ onFbLogin, error, isLoading, isDisabled }) {
  return (
    <>
      <Grid
        container
        direction='row'
        justifyContent='center'
        alignItems='center'
        height='100vh'
        marginTop={-4}>
        <Grid item xs={12} sm={8} md={6} lg={5} xl={4}>
          <Box>
            <Box
              display={'flex'}
              flexDirection='column'
              alignItems='center'
              justifyContent='center'
              sx={{
                bgcolor: 'common.white',
                borderRadius: 4,
                paddingRight: 4,
                paddingLeft: 4,
                paddingTop: 5,
                paddingBottom: 5,
              }}>
              <Box display={'flex'} height='48px' pr={1}>
                <SquadStackPartnersLogo />
              </Box>
              <Typography
                variant='h6'
                sx={{
                  fontWeight: '600',
                  fontSize: 24,
                  lineHeight: '32px',
                  color: 'text.primary',
                  marginTop: 4,
                }}>
                Login / Signup
              </Typography>
              <LoadingButton
                disabled={isDisabled}
                loading={isLoading}
                fullWidth
                sx={{ marginTop: 4, textTransform: 'none', fontWeight: 600 }}
                variant='contained'
                size='large'
                color='success'
                onClick={onFbLogin}
                startIcon={<FacebookRoundedIcon />}>
                Continue with Facebook
              </LoadingButton>
              {error ? (
                <Typography
                  variant='body'
                  sx={{
                    color: 'text.error',
                  }}
                  alignSelf='flex-start'
                  marginTop={2}>
                  {error}
                </Typography>
              ) : null}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default FacebookLoginView;
