import React from 'react';
import emptyBadge from '../../assets/emptyBadge.png';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

const DataLoading = () => {
  return (
    <Box
      data-testid='data-loading'
      display='flex'
      flexDirection='column'
      alignItems='center'
      justifyContent='space-between'
      padding={2}
      paddingTop='40px'>
      <Typography
        sx={{
          fontWeight: '700',
          fontSize: 24,
          lineHeight: '32px',
          color: 'text.primary',
          textAlign: 'center',
        }}>
        Welcome to Squadstack
      </Typography>
      <img
        src={emptyBadge}
        style={{ maxWidth: '143px', minWidth: '143px', marginTop: '32px' }}
      />
      <Box sx={{ width: '300px', marginTop: 4 }}>
        <LinearProgress />
      </Box>
      <Typography
        variant='subtitle2'
        sx={{
          fontSize: 20,
          lineHeight: '32px',
          color: 'text.primary',
          textAlign: 'center',
          marginTop: 2,
          maxWidth: 270,
          fontWeight: 400,
        }}>
        Please wait while we load the data.
      </Typography>
    </Box>
  );
};

export default DataLoading;
