import { useState, useEffect, useCallback } from 'react';

export const useNetworkStatus = () => {

  const [toastState, setToastState] = useState({
    isVisible: false,
    msg: '',
    severity: 'success',
    autoHideDuration: null,
    isPersistent: false,
  });

  const handleConnectionChange = useCallback(() => {
    if (document.visibilityState === 'visible') {
      if (navigator.onLine) {
        setToastState({
          isVisible: true,
          msg: 'You are online.',
          severity: 'success',
          autoHideDuration: 2500,
          isPersistent: false,
        });
      } else {
        setToastState({
          isVisible: true,
          msg: 'You are offline. Please check your internet connection.',
          severity: 'error',
          autoHideDuration: null,
          isPersistent: true,
        });
      }
    }
  }, []);

  useEffect(() => {
    window.addEventListener('online', handleConnectionChange);
    window.addEventListener('offline', handleConnectionChange);

    return () => {
      window.removeEventListener('online', handleConnectionChange);
      window.removeEventListener('offline', handleConnectionChange);
    };
  }, [handleConnectionChange]);

  const onClose = () => {
    setToastState({
      ...toastState,
      isVisible: false,
    });
  }

  return { toastState, onClose };
};
