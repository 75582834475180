import * as React from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Router from './router';
import { ThemeProvider } from '@mui/material/styles';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import * as Sentry from '@sentry/react';
import { Toaster } from 'react-hot-toast';
import { Support, Logger } from './services';
import { useNetworkStatus } from './hooks';
import { Toast } from './components/';
export const DEFAULT_RETRY_COUNT = 3;
export const DEFAULT_STALE_TIME = 0;
export const DEFAULT_CACHE_TIME = 300000; // 5 minutes
import { theme } from './theme';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: DEFAULT_RETRY_COUNT,
      cacheTime: DEFAULT_CACHE_TIME,
      staleTime: DEFAULT_STALE_TIME,
    },
    mutations: {
      retry: DEFAULT_RETRY_COUNT,
    },
  },
});

if (process.env.REACT_APP_ENV === 'production') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [],

    // disbaling apm, since not needed and consumes our sentry quota
    tracesSampleRate: 0,
    ignoreErrors: [
      'getReadModeRender',
      'getReadModeConfig',
      'getReadModeExtract',
    ],
  });
  Logger.init({
    datadog: {
      enable: true,
      service: 'tof-webapp',
      clientToken: process.env.REACT_APP_DATADOG,
      site: 'datadoghq.com',
      env: process.env.REACT_APP_ENV,
      forwardErrorsToLogs: false,
      sessionSampleRate: 100,
    },
  });

}

Support.init({
  enable: true,
});

export default function App() {
  const { toastState, onClose } = useNetworkStatus();

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <Box sx={{ flexGrow: 1 }}>
            <CssBaseline />
            <Box>
              <Toast
                isVisible={toastState.isVisible}
                autoHideDuration={toastState.autoHideDuration}
                severity={toastState.severity}
                msg={toastState.msg}
                onClose={onClose}
                isPersistent={toastState.isPersistent}
              />
              <Router />
            </Box>
          </Box>
        </ThemeProvider>
      </QueryClientProvider>
      <Toaster />
    </>
  );
}
