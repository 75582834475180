import React, { useEffect, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import ButtonBase from '@mui/material/ButtonBase';
import {
  ASSESSMENT_STATUS,
  STAGE_STATUS,
  WAITING_TIME_TEXT,
} from './constants';
import { mixpanel } from '../../services';
import LinearProgress from '@mui/material/LinearProgress';

const ScoreText = ({ score }) => {
  return (
    <Box
      display='flex'
      flexDirection={{ xs: 'column', md: 'row' }}
      alignItems='center'
      marginLeft='auto'
      marginTop={1}>
      <Typography sx={{ fontSize: 14, fontWeight: 400 }} color='text.secondary'>
        Score
      </Typography>
      <Typography
        sx={{ fontSize: 24, fontWeight: 700 }}
        color='text.primary'
        marginLeft={1}>
        {score}
      </Typography>
    </Box>
  );
};

const IndexIcon = ({ text, disabled }) => {
  return (
    <Box
      data-testid='IndexIcon'
      backgroundColor={disabled ? 'primary.light' : 'primary.main'}
      display='flex'
      flexDirection='row'
      justifyContent='center'
      alignItems='center'
      borderRadius={12}
      sx={{ maxWidth: '24px', maxHeight: '24px' }}
      padding={1}>
      <Typography sx={{ fontSize: 18, fontWeight: 700 }} color='common.white'>
        {text}
      </Typography>
    </Box>
  );
};

const Label = ({ type, text }) => {
  const labelConfig = useMemo(() => {
    switch (type) {
      case ASSESSMENT_STATUS.IN_PROGRESS:
        return {
          text: 'In Progress',
          textColor: 'warning.dark',
          color: 'warning.main',
          icon: null,
        };

      case ASSESSMENT_STATUS.IN_REVIEW:
        return {
          text: 'In Review',
          textColor: 'warning.dark',
          color: 'warning.warning300',
          icon: null,
        };

      case ASSESSMENT_STATUS.FAILED:
        return {
          text: 'Failed',
          color: 'error.main',
          textColor: 'error.dark',
          icon: null,
        };

      case ASSESSMENT_STATUS.PASSED:
        return {
          text: 'Passed',
          color: 'success.success100',
          textColor: 'success.success700',
          icon: null,
        };

      case ASSESSMENT_STATUS.REATTEMPT:
        return {
          text: text || 'Re-attempt',
          color: 'background.default',
          textColor: 'tint.lavender600',
          icon: (
            <RefreshOutlinedIcon
              sx={{
                color: 'tint.lavender600',
                transform: 'rotateY(180deg)',
                marginRight: '4px',
              }}
            />
          ),
        };

      default:
        return null;
    }
  }, [type, text]);

  return (
    <Box
      data-testid='label-icon'
      display='flex'
      justifyContent='center'
      alignItems='center'
      borderRadius={20}
      paddingX={2}
      paddingY='4px'
      backgroundColor={labelConfig?.color}
      marginTop={1}>
      {labelConfig?.icon}
      <Typography
        sx={{ fontSize: 14, fontWeight: 700 }}
        color={labelConfig?.textColor}>
        {labelConfig?.text}
      </Typography>
    </Box>
  );
};

const TimeChip = ({ time, stageStatus }) => {
  return (
    <Box
      display='flex'
      justifyContent='center'
      alignItems='center'
      backgroundColor='background.default'
      borderRadius={20}
      paddingX={1}
      paddingY={1}
      marginTop={1}
      boxShadow={
        stageStatus === STAGE_STATUS.LOCKED || stageStatus === STAGE_STATUS.HOLD
          ? 1
          : 0
      }>
      <AccessTimeIcon
        sx={{ color: 'text.primary', width: '24px', height: '24px' }}
      />
      <Typography
        sx={{ fontSize: 14, fontWeight: 400 }}
        color='text.primary'
        marginLeft='10px'>
        {time} Mins.
      </Typography>
    </Box>
  );
};

const WorkflowCard = ({
  testId,
  title,
  order,
  data,
  handleOnClick,
  isWorkflowFailed,
  lastActiveAssessment,
  lastSubmittedAssessment,
  isLastStage,
}) => {
  const [progress, setProgress] = useState(0);
  const [hasTimerEnded, setTimerEnded] = useState(false);

  const {
    assessment_status: assessmentStatus,
    status: status,
    score: assessmentScore,
    redirect_url: redirectUrl,
    metadata: assessmentMetaData,
    id: stageId,
    est_time: estTime,
    retry_button_text: retryButtonText,
  } = data;

  const isLockedOrHold =
    status === STAGE_STATUS.LOCKED || status === STAGE_STATUS.HOLD;

  const isCardClickable = useMemo(() => {
    if (
      (status === STAGE_STATUS.ACTIVE &&
        redirectUrl &&
        assessmentStatus !== ASSESSMENT_STATUS.PASSED &&
        assessmentStatus !== ASSESSMENT_STATUS.IN_REVIEW &&
        assessmentStatus !== ASSESSMENT_STATUS.FAILED &&
        (assessmentStatus === ASSESSMENT_STATUS.REATTEMPT ||
          lastSubmittedAssessment?.id !== stageId)) ||
      stageId === 'download-app'
    ) {
      return true;
    } else false;
  }, [
    status,
    redirectUrl,
    assessmentStatus,
    stageId,
    lastSubmittedAssessment?.id,
  ]);

  const leftIcon = useMemo(() => {
    if (
      status === STAGE_STATUS.COMPLETED &&
      assessmentStatus !== ASSESSMENT_STATUS.FAILED
    ) {
      return <CheckCircleIcon sx={{ color: 'success.main' }} />;
    } else if (assessmentStatus === ASSESSMENT_STATUS.REATTEMPT) {
      return <CheckCircleIcon sx={{ color: 'tint.lavender600' }} />;
    } else {
      return (
        <IndexIcon text={order} disabled={isLockedOrHold ? true : false} />
      );
    }
  }, [status, assessmentStatus, order, isLockedOrHold]);

  const rightIcon = useMemo(() => {
    if (isLockedOrHold) {
      return (
        <LockOutlinedIcon
          sx={{
            color: 'text.secondary',
            width: '24px',
            height: '24px',
          }}
        />
      );
    } else if (isCardClickable) {
      return (
        <OpenInNewOutlinedIcon
          sx={{
            color: 'text.secondary',
            width: '24px',
            height: '24px',
          }}
        />
      );
    } else
      return (
        <Box
          data-testid='EmptyRightIcon'
          sx={{
            width: '24px',
            height: '24px',
          }}
        />
      );
  }, [isCardClickable, isLockedOrHold]);

  const labelIcon = useMemo(() => {
    switch (assessmentStatus) {
      case ASSESSMENT_STATUS.IN_PROGRESS:
        return lastSubmittedAssessment?.id === stageId ? (
          <Label type={ASSESSMENT_STATUS.IN_REVIEW} />
        ) : (
          <Label type={ASSESSMENT_STATUS.IN_PROGRESS} />
        );
      case ASSESSMENT_STATUS.FAILED:
        return <Label type={ASSESSMENT_STATUS.FAILED} />;
      case ASSESSMENT_STATUS.PASSED:
        return <Label type={ASSESSMENT_STATUS.PASSED} />;
      case ASSESSMENT_STATUS.IN_REVIEW:
        return <Label type={ASSESSMENT_STATUS.IN_REVIEW} />;
      case ASSESSMENT_STATUS.REATTEMPT:
        return (
          <Label type={ASSESSMENT_STATUS.REATTEMPT} text={retryButtonText} />
        );
      default:
        if (
          (isLockedOrHold || status === STAGE_STATUS.ACTIVE) &&
          !isWorkflowFailed
        ) {
          return <TimeChip time={estTime} stageStatus={status} />;
        } else return null;
    }
  }, [
    assessmentStatus,
    lastSubmittedAssessment?.id,
    stageId,
    status,
    isWorkflowFailed,
    estTime,
    retryButtonText,
    isLockedOrHold,
  ]);

  const metaData = useMemo(() => {
    if (
      assessmentStatus === ASSESSMENT_STATUS.PASSED &&
      lastActiveAssessment?.id === stageId
    ) {
      return lastActiveAssessment?.metadata;
    } else if (
      (assessmentStatus === ASSESSMENT_STATUS.IN_PROGRESS ||
        assessmentStatus === ASSESSMENT_STATUS.IN_REVIEW) &&
      lastSubmittedAssessment?.id === stageId &&
      title?.toLowerCase() !== 'phone interview' &&
      !hasTimerEnded
    ) {
      return WAITING_TIME_TEXT.IN_REVIEW;
    } else {
      return assessmentMetaData;
    }
  }, [
    assessmentStatus,
    lastActiveAssessment?.id,
    lastActiveAssessment?.metadata,
    stageId,
    lastSubmittedAssessment?.id,
    title,
    hasTimerEnded,
    assessmentMetaData,
  ]);

  const shouldShowTimer =
    (metaData === WAITING_TIME_TEXT.IN_PASSED ||
      metaData === WAITING_TIME_TEXT.IN_REVIEW) &&
    !isLastStage;

  useEffect(() => {
    const timer = setInterval(() => {
      if (shouldShowTimer) {
        setProgress((oldProgress) => {
          if (oldProgress === 100) {
            setTimerEnded(true);
            clearInterval(timer);
          }
          const diff = 100 / 60;
          return Math.min(oldProgress + diff, 100);
        });
      }
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [shouldShowTimer]);

  const handleCardClick = () => {
    if (isCardClickable) {
      handleOnClick(data, order);
      mixpanel.trackEvent('Workflow Link Clicked', {
        redirectUrl,
      });
    }
  };

  return (
    <ButtonBase
      data-testid={testId}
      sx={{
        width: '100%',
        maxWidth: 568,
        marginTop: 2,
      }}
      onClick={handleCardClick}
      disabled={!isCardClickable}>
      <Card
        variant={isLockedOrHold ? 'outlined' : 'elevation'}
        sx={{
          boxShadow: 0,
          '&:hover': {
            boxShadow: 1,
          },
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          maxWidth: 568,
          padding: 3,
          borderRadius: 2,
          backgroundColor: isLockedOrHold
            ? 'background.default'
            : 'common.white',
        }}>
        <Box display='flex' justifyContent='center'>
          <Box
            display='flex'
            flexDirection='row'
            alignItems='center'
            width='100%'>
            <Box>{leftIcon}</Box>

            <Typography
              sx={{
                fontSize: 20,
                fontWeight: 600,
                textAlign: 'left',
                flexGrow: 1,
              }}
              color={isLockedOrHold ? 'text.secondary' : 'text.primary'}
              marginLeft={2}
              marginRight={2}>
              {title}
            </Typography>
          </Box>
          <Box display='flex' alignItems='center'>
            {rightIcon}
          </Box>
        </Box>

        <Box
          display='flex'
          flexDirection='row'
          justifyContent='space-between'
          alignItems='center'>
          <Box display='flex' flexDirection='column' alignItems='flex-start'>
            {labelIcon ? labelIcon : null}
            {metaData ? (
              <Typography
                sx={{
                  fontSize: 16,
                  fontWeight: 400,
                  textAlign: 'left',
                  marginTop: 1,
                }}>
                {metaData}
              </Typography>
            ) : null}
          </Box>

          {assessmentScore ? <ScoreText score={assessmentScore} /> : null}
        </Box>
        {shouldShowTimer ? (
          <LinearProgress
            sx={{ marginTop: 1 }}
            variant='determinate'
            value={progress}
          />
        ) : null}
      </Card>
    </ButtonBase>
  );
};

export default WorkflowCard;
