import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React, { forwardRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as SquadStackPartnersLogo } from '../../assets/squadStackPartnersLogo-full.svg';
import { Logger, Support } from '../../services';
import { useQueryParams } from '../../hooks/useQueryParams';

export const NavbarVariants = {
  SCREENING: 'SCREENING',
  TRAINING: 'TRAINING',
};

const Logout = () => {
  const navigate = useNavigate();
  const [searchParams] = useQueryParams();
  const onLogoutClick = () => {
    Logger.clearUser();
    Logger.info('Logout Button Clicked');
    window.postMessage('tof_logout', '*');
    localStorage.clear();
    navigate(`/auth?${searchParams.toString()}`);
    Support.reset();
  };

  return (
    <Box marginLeft='auto' justifyContent='center'>
      <LoadingButton
        data-testid={'logout-button'}
        fullWidth
        sx={{
          textTransform: 'none',
          border: '1px solid',
          borderColor: 'common.white',
        }}
        variant='text'
        size='small'
        color='success'
        onClick={onLogoutClick}
        loading={false}>
        <Typography
          sx={{ fontWeight: 500, cursor: 'pointer' }}
          paddingX={1}
          color='common.white'>
          Logout
        </Typography>
      </LoadingButton>
    </Box>
  );
};

const Navbar = forwardRef(({ variant = NavbarVariants.TRAINING }, ref) => {
  return (
    <Box
      ref={ref}
      position='fixed'
      top={0}
      display='flex'
      width='100%'
      alignItems='center'
      backgroundColor='primary.main'
      p={2}
      zIndex={100}>
      <Box height='32px' pr={1}>
        <SquadStackPartnersLogo />
      </Box>
      {variant === NavbarVariants.TRAINING ? (
        <Typography
          variant='h6'
          component='div'
          sx={{ flexGrow: 1, marginLeft: 4 }}>
          SquadStack Training Platform
        </Typography>
      ) : (
        <Logout />
      )}
    </Box>
  );
});

Navbar.displayName = 'Navbar';

export default Navbar;
