import React, { useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import toast from 'react-hot-toast';
import Typography from '@mui/material/Typography';
import { mixpanel } from '../../services';
import GppMaybeIcon from '@mui/icons-material/GppMaybe';

const DisabledAppDialog = () => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [isError, setIsError] = useState(false);

  const handleCopyToClipboard = async () => {
    mixpanel.trackEvent('Copy to Clipboard Button Clicked');
    try {
      if (typeof navigator?.clipboard?.writeText === 'function') {
        await navigator.clipboard.writeText(window.location.href);
        toast.success('Copied Successfully!');
      }
    } catch (error) {
      mixpanel.trackEvent('Copy to Clipboard Action Failed', error);
      setIsError(true);
    }
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={true}
      aria-labelledby='responsive-dialog-title'>
      <DialogTitle
        id='responsive-dialog-title'
        sx={{ display: 'flex', alignItems: 'center' }}>
        <GppMaybeIcon sx={{ color: 'text.error', marginRight: 1 }} />{' '}
        Unsupported Browser
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Our app is currently only supported on chrome. Please use Chrome
          Browser to use our app.
        </DialogContentText>

        <Typography
          sx={{ cursor: 'pointer', marginTop: 3 }}
          onClick={isError ? null : handleCopyToClipboard}>
          {window.location.href}
        </Typography>
      </DialogContent>
      <DialogActions>
        {!isError ? (
          <Button
            sx={{ textTransform: 'none' }}
            onClick={handleCopyToClipboard}>
            Copy Link
          </Button>
        ) : null}
      </DialogActions>
    </Dialog>
  );
};

export default DisabledAppDialog;
