import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import { ReactComponent as SquadStackPartnersLogo } from '../../assets/squadStackPartnersLogo-full-dark.svg';
import { ReactComponent as Resend } from '../../assets/resend.svg';
import { useTheme } from '@mui/material/styles';
import { Carousel } from '../../components/Carousel';

function OTPVerificationView({
  phoneNumber,
  isVerifying,
  error,
  otp,
  handleOtpInput,
  onVerifyOTP,
  isVerifyDisabled,
  loginIsLoading,
  onResendOTP,
  resendButtonDisabledTime,
}) {
  const theme = useTheme();
  return (
    <Grid
      container
      direction='row'
      justifyContent='center'
      alignItems='center'
      height='100vh'
      overflow='hidden'>
      <Grid
        item
        lg={6}
        xl={6}
        sx={{
          display: {
            xs: 'none',
            sm: 'none',
            md: 'none',
            lg: 'block',
            xl: 'block',
          },
        }}>
        <Carousel />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
        <Box>
          <Box
            display={'flex'}
            flexDirection='column'
            justifyContent='center'
            height='100vh'
            paddingX={{ xs: 4, sm: 20, md: 30, lg: 15, xl: 25 }}
            paddingY={5}
            sx={{
              bgcolor: 'common.white',
              alignItems: {
                xs: 'center',
                sm: 'center',
                md: 'center',
                lg: 'flex-start',
                xl: 'flex-start',
              },
            }}>
            <Box display={'flex'} height='48px' pr={1}>
              <SquadStackPartnersLogo />
            </Box>
            <Typography
              variant='h6'
              sx={{
                fontWeight: '600',
                fontSize: 24,
                lineHeight: '32px',
                color: 'text.primary',
                marginTop: 4,
              }}>
              OTP Verification
            </Typography>
            <Typography
              variant='h6'
              sx={{
                color: 'text.secondary',
                marginTop: 2,
                fontWeight: 400,
              }}
              alignSelf='flex-start'>
              6 Digit OTP sent to +91 {phoneNumber}
            </Typography>
            <Box
              display='flex'
              alignItems='center'
              width='100%'
              sx={{ marginTop: 2 }}>
              <Typography
                variant='body'
                sx={{
                  color: 'text.secondary',
                }}>
                Resend OTP in {resendButtonDisabledTime} secs
              </Typography>
              <Button
                color={resendButtonDisabledTime > 0 ? 'input' : 'secondary'}
                sx={{ textTransform: 'none', fontSize: 16 }}
                onClick={onResendOTP}>
                <Resend
                  fill={
                    resendButtonDisabledTime > 0
                      ? theme.palette.input.main
                      : theme.palette.success.main
                  }
                />{' '}
                Resend
              </Button>
            </Box>
            <Box width='100%'>
              <form onSubmit={onVerifyOTP}>
                <TextField
                  id='outlined-basic'
                  label={otp.length ? '' : 'Enter OTP'}
                  variant='outlined'
                  fullWidth
                  size='small'
                  sx={{ marginTop: 1 }}
                  value={otp}
                  onChange={handleOtpInput}
                  error={Boolean(error)}
                  inputProps={{ maxLength: 6, 'data-testid': 'otp-input' }}
                  InputLabelProps={{ shrink: false }}
                />
              </form>
            </Box>
            {error ? (
              <Typography
                variant='body'
                sx={{
                  lineHeight: '32px',
                  color: 'text.error',
                }}
                alignSelf='flex-start'>
                {error}
              </Typography>
            ) : null}
            <LoadingButton
              data-testid='verify-btn'
              fullWidth
              sx={{ marginTop: 4, textTransform: 'none', fontWeight: 600 }}
              variant='contained'
              size='large'
              color='success'
              onClick={onVerifyOTP}
              loading={isVerifying}
              disabled={isVerifyDisabled || loginIsLoading}>
              {isVerifying ? 'Verifying...' : 'Verify'}
            </LoadingButton>
            <Box sx={{ marginTop: 1 }}>
              <Typography
                variant='subtitle2'
                sx={{
                  fontSize: 14,
                  color: 'text.secondary',
                  fontWeight: 400,
                }}>
                By signing up, you agree to the&nbsp;
                <Link
                  href='https://www.squadrunapp.com/terms-condition'
                  color='tint.main'
                  variant='subtitle2'
                  underline='none'
                  target='_blank'
                  rel='noopener noreferrer'
                  fontWeight={600}>
                  terms and conditions
                </Link>
                &nbsp;and&nbsp;
                <Link
                  href='https://www.squadrunapp.com/privacy-policy'
                  color='tint.main'
                  variant='subtitle2'
                  underline='none'
                  target='_blank'
                  rel='noopener noreferrer'
                  fontWeight={600}>
                  privacy policy
                </Link>
              </Typography>
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}

export default OTPVerificationView;
