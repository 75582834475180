import React, { useState, useRef, useEffect, useCallback } from 'react';
import OTPVerificationView from './OTPVerificationView';
import { useLocation, useNavigate } from 'react-router-dom';
import { fireNetworkRequest, useMutation } from '../../network';
import { Logger, Support } from '../../services';
import { STORAGE_KEYS } from '../../constants';
import { useQueryParams } from '../../hooks/useQueryParams';

const RESEND_OTP_TIME_LIMIT = 30;

function OTPVerificationContainer() {
  const [otp, setOTP] = useState('');
  const [error, setError] = useState('');
  const [isVerifyDisabled, setIsVerifyDisabled] = useState(true);
  const [resendButtonDisabledTime, setResendButtonDisabledTime] = useState(
    RESEND_OTP_TIME_LIMIT
  );

  const { state } = useLocation();

  const handleOtpInput = useCallback((e) => {
    const otp = e.target.value;
    setError('');
    setOTP(otp);
    if (otp.length === 6) setIsVerifyDisabled(false);
    else setIsVerifyDisabled(true);
  }, []);

  const navigate = useNavigate();

  const isSpeechAssessment = state?.speech_assessment === 'true';

  const [searchParams] = useQueryParams();

  const { mutate, isLoading } = useMutation(
    () =>
      fireNetworkRequest({
        url: `/players/api/tof/phone-number-login/${
          isSpeechAssessment ? '?isSpeechAssessment=true' : ''
        }`,
        method: 'post',
        data: {
          phone_number: `+91${state?.phoneNumber}`,
          otp,
          source: state?.source || '',
        },
      }),
    {
      onSuccess: (data) => {
        const playerData = data.data?.player;
        const token = data.data?.token;

        const userInfo = {
          userId: playerData?.id,
          firstName: playerData?.first_name,
          lastName: playerData?.last_name,
          email: playerData?.email_id,
          phoneNumber: playerData?.phone_number,
          source: state?.source,
          referenceId: playerData?.reference_id,
        };

        localStorage.setItem(
          STORAGE_KEYS.USER_DETAILS,
          JSON.stringify(userInfo)
        );

        Logger.setUser(userInfo);

        Logger.info('OTP Verified Successfully', {
          phone_number: state?.phoneNumber,
          otp,
          source: state?.source,
          data: data?.data,
        });
        if (isSpeechAssessment) {
          window.location = `https://training.squadrun.co/?player_id=${data?.data?.player_id}`;
        } else {
          Support.setUserInfo(userInfo);
          localStorage.setItem(STORAGE_KEYS.AUTH, token);
          navigate(`/workflow?${searchParams.toString()}`);
        }
      },
      onError: (e) => {
        Logger.error(new Error('OTP Verification Failed'), {
          otp,
          e,
          source: state?.source,
        });
        if (e.response.status >= 400 && e.response.status < 500) {
          setError(e?.response?.data?.error_display_message);
        }
      },
    }
  );

  const onVerifyOTP = useCallback(
    (e) => {
      e?.preventDefault();
      Logger.info(`Verify OTP Button Clicked`);
      if (isLoading) return;
      const isOTPValid = /^\d{6}$/.test(otp);
      if (isOTPValid) {
        setError('');
        mutate();
      } else {
        Logger.error(new Error('Invalid OTP Entered'));
        setError('Please enter a valid OTP!');
      }
    },
    [isLoading, mutate, otp]
  );

  const { mutate: loginMutate, isLoading: loginIsLoading } = useMutation(
    () =>
      fireNetworkRequest({
        url: '/players/api/v13/otp/',
        method: 'post',
        data: {
          phone_number: `+91${state?.phoneNumber}`,
          isResend: true,
        },
      }),
    {
      onSuccess: () => {
        Logger.info(`Resent OTP Successfully`, {
          phoneNumber: state?.phoneNumber,
          otp: otp,
        });
      },
      onError: (e) => {
        Logger.error(new Error('Resend OTP Failed'), {
          phoneNumber: state?.phoneNumber,
          isResend: true,
          otp: otp,
        });
        if (e.response.status >= 400 && e.response.status < 500) {
          setError(e.response.data.error_message);
        }
      },
    }
  );

  const resendOtpTimerInterval = useRef(null);

  const startResendOtpTimer = useCallback(() => {
    if (resendOtpTimerInterval.current) {
      clearInterval(resendOtpTimerInterval.current);
    }
    resendOtpTimerInterval.current = setInterval(() => {
      setResendButtonDisabledTime((currentTime) => {
        if (currentTime - 1 <= 0) {
          clearInterval(resendOtpTimerInterval.current);
        }
        return currentTime - 1;
      });
    }, 1000);
  }, []);

  useEffect(() => {
    startResendOtpTimer();

    return () => {
      if (resendOtpTimerInterval.current) {
        clearInterval(resendOtpTimerInterval.current);
      }
    };
  }, [startResendOtpTimer]);

  const onResendOTP = useCallback(() => {
    Logger.info(`Resend OTP Button Clicked`);
    if (resendButtonDisabledTime <= 0 && !loginIsLoading) {
      setError('');
      setOTP('');
      setResendButtonDisabledTime(RESEND_OTP_TIME_LIMIT);
      startResendOtpTimer();
      loginMutate();
    }
  }, [
    loginMutate,
    resendButtonDisabledTime,
    startResendOtpTimer,
    loginIsLoading,
  ]);

  useEffect(() => {
    if (!state || !state?.phoneNumber) {
      navigate('/auth');
    }
  }, [navigate, state]);

  return (
    <OTPVerificationView
      phoneNumber={state?.phoneNumber}
      isVerifying={isLoading}
      isVerifyDisabled={isVerifyDisabled}
      error={error}
      otp={otp}
      handleOtpInput={handleOtpInput}
      onVerifyOTP={onVerifyOTP}
      resendButtonDisabledTime={resendButtonDisabledTime}
      onResendOTP={onResendOTP}
      loginIsLoading={loginIsLoading}
    />
  );
}

export default OTPVerificationContainer;
