import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";

const queryParamsToPreserve = ['source', 'speech_assessment'];

function deleteQueryParamsExcept(searchParams, paramsToKeep) {
  for (const param of searchParams.keys()) {
    if (!paramsToKeep.includes(param)) {
      searchParams.delete(param);
    }
  }

  return searchParams;
}


export const useQueryParams = () => {

  const [searchParams, setSeachParams] = useSearchParams();

  const requiredQueryParams = useMemo(() => {
    return deleteQueryParamsExcept(searchParams, queryParamsToPreserve);
  }, [searchParams]);

  return [requiredQueryParams, setSeachParams];
};
