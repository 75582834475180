import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import CarouselProgessBar from './CarouselProgessBar';
import carouselImg1 from '../../assets/authCarousel/img1.webp';
import carouselImg2 from '../../assets/authCarousel/img2.webp';
import carouselImg3 from '../../assets/authCarousel/img3.webp';

const defaultData = [
  { label: 'Item 1', img: carouselImg1 },
  { label: 'Item 2', img: carouselImg2 },
  { label: 'Item 3', img: carouselImg3 },
];

const Carousel = ({ items = defaultData, duration = 2500 }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleSelectedSlide = (index) => {
    setCurrentIndex(index);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((currentIndex) => (currentIndex + 1) % items.length);
    }, duration);
    return () => clearInterval(intervalId);
  }, [currentIndex, duration, items]);

  return (
    <Box height='100vh'>
      <Box width='100%' height='100%' position='relative'>
        {items && (
          <img
            src={items[currentIndex].img}
            style={{
              objectFit: 'cover',
              width: '100%',
              height: '100%',
              transition: '3s ease-out',
            }}
          />
        )}
        <Box
          display='flex'
          justifyContent='center'
          bottom='0'
          marginBottom='10px'
          width='100%'
          position='absolute'
          gap='8px'>
          <CarouselProgessBar
            items={items}
            currentIndex={currentIndex}
            handleSelectedSlide={handleSelectedSlide}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Carousel;
