import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import GppMaybeIcon from '@mui/icons-material/GppMaybe';

const TechnicalErrorDialog = ({ msg }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      fullScreen={fullScreen}
      open={true}
      aria-labelledby='responsive-dialog-title'>
      <DialogTitle
        id='responsive-dialog-title'
        sx={{ display: 'flex', alignItems: 'center' }}>
        <GppMaybeIcon sx={{ color: 'text.error', marginRight: 1 }} />{' '}
        Technical Error
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {msg}
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default TechnicalErrorDialog;
