import { useQuery as useQueryRC } from '@tanstack/react-query';
import toast from 'react-hot-toast';

const baseErrorMessage = `Something went wrong! Please try again after sometime.`;

export const useAuthenticatedQuery = (key, queryFn, config) => {
  const query = useQueryRC(key, queryFn, {
    ...config,
    onError: (e) => {
      if (e?.response?.status === 401) {
        localStorage.clear();
        location.href = '/auth';
      }
      const errorMessage = e?.response?.data?.error_display_message || baseErrorMessage;
      toast.error(errorMessage);
      config?.onError?.(e);
    },
  });

  return query;
};

export const useQuery = (key, queryFn, config) => {
  const query = useQueryRC(key, queryFn, {
    ...config,
    onError: (e) => {
      const errorMessage = e?.response?.data?.error_display_message || baseErrorMessage;
      toast.error(errorMessage);
      config?.onError?.(e);
    },
  });

  return query;
};
