import { useMutation as useMutationRC } from '@tanstack/react-query';
import toast from 'react-hot-toast';

const baseErrorMessage = `Something went wrong! Please try again after sometime.`;

export const useAuthenticatedMutation = (queryFn, config) => {
  const mutation = useMutationRC(queryFn, {
    ...config,
    onError: (e) => {
      if (e?.response?.status === 401) {
        localStorage.clear();
        location.href = '/auth';
      }
      const errorMessage = e?.response?.data?.error_display_message || baseErrorMessage;
      toast.error(errorMessage);
      config?.onError?.(e);
    },
  });

  return mutation;
};

export const useMutation = (queryFn, config) => {
  const mutation = useMutationRC(queryFn, {
    ...config,
    onError: (e) => {
      const errorMessage = e?.response?.data?.error_display_message || baseErrorMessage;
      toast.error(errorMessage);
      config?.onError?.(e);
    },
  });

  return mutation;
};
