import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export const Redirect = () => {
  return (
    <Box height='100%' alignContent='center' p={8}>
      <Typography
        variant='h4'
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}>
        Please Wait...
      </Typography>
      <Typography
        variant='h6'
        sx={{
          display: 'flex',
          justifyContent: 'center',
          textAlign: 'center',
          mt: 4,
        }}>
        You are being redirected in 5 seconds, please close the window in case
        you are not redirected
      </Typography>
    </Box>
  );
};
