import axios from 'axios';
import * as Sentry from '@sentry/browser';
import {
  BadNetwork,
  ServerError,
  BrowserCancel,
  BadRequest,
  BadAuthentication,
  ForbiddenAccess,
  NotFound,
  APIError,
  Cancelled,
  TooManyRequests,
} from './errors';
import { HttpError } from './http';
import { Logger } from '../services';
import { STORAGE_KEYS } from '../constants';

export const fireNetworkRequest = async (request, config = {}, signal) => {
  try {
    const { isAuthenticated = false } = config;
    const headers = {
      'Content-type': 'application/json; charset=UTF-8',
      ...request.headers,
    };
    if (isAuthenticated) {
      if (localStorage.getItem(STORAGE_KEYS.AUTH)) {
        headers['Authorization'] = `Token ${localStorage.getItem(
          STORAGE_KEYS.AUTH
        )}`;
      }
    }

    return await axios({
      baseURL: process.env.REACT_APP_BASE_URL,
      ...request,
      signal,
      headers,
    });
  } catch (error) {
    Logger.error(new Error(`Network Error: ${error.config.url}`), { error });
    let err = error;
    if (axios.isAxiosError(error)) {
      if (axios.isCancel(error)) {
        err = new Cancelled();
      } else {
        err = new HttpError(request, err?.response);
        const status = err.status;
        if (!Number.isFinite(status)) {
          err = new BadNetwork(err);
        } else if (status === 0) {
          err = new BrowserCancel(err);
        } else if (status >= 500) {
          err = new ServerError(err);
        } else if (status === 400) {
          err = new BadRequest(err);
        } else if (status === 401) {
          err = new BadAuthentication(err);
        } else if (status === 403) {
          err = new ForbiddenAccess(err);
        } else if (status === 404) {
          err = new NotFound(err);
        } else if (status === 429) {
          err = new TooManyRequests(err);
        } else {
          err = new APIError(err);
        }
      }
    }
    if (!(err instanceof Cancelled) && !(err instanceof BrowserCancel)) {
      Sentry.captureException(err, { extra: err });
    }
    throw error;
  }
};
