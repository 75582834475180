import { Box } from '@mui/material';
import React from 'react';

const Dot = ({ selected, handleSelectedSlide, index }) => {
  const handleClick = () => {
    handleSelectedSlide(index);
  };

  return (
    <Box
      onClick={handleClick}
      sx={{
        cursor: 'pointer',
      }}>
      <Box
        width='8px'
        height='8px'
        borderRadius='50%'
        bgcolor={selected ? '#39CEA0' : '#E0E0E0'}
      />
    </Box>
  );
};

const CarouselProgessBar = ({ items, currentIndex, handleSelectedSlide }) => {
  return (
    <>
      {items.map((item, index) => (
        <Dot
          key={item.img}
          selected={index === currentIndex}
          index={index}
          handleSelectedSlide={handleSelectedSlide}
        />
      ))}
    </>
  );
};

export default CarouselProgessBar;
