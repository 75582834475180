import { datadogLogs } from '@datadog/browser-logs';

export const serviceNames = {
  datadog: 'datadog',
};

export class DatadogLoggerService {
  name;
  config;

  constructor(config) {
    datadogLogs.init(config);
    this.name = serviceNames.datadog;
  }

  async init(config) {
    datadogLogs.init(config);
  }

  error(exception, extra = {}) {
    datadogLogs.logger.error(
      exception?.message ?? 'No message provided',
      extra
    );
  }

  info(message, extra = {}) {
    datadogLogs.logger.info(message ?? 'No message provided', extra);
  }

  warning(message, extra = {}) {
    datadogLogs.logger.warn(message ?? 'No message provided', extra);
  }

  setUser(userDetails) {
    datadogLogs.setUser(userDetails);
  }

  setGlobalProperties(properties) {
    datadogLogs.setGlobalContext(properties);
  }

  clearUser() {
    datadogLogs.clearUser();
    return;
  }
}
