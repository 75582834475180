import React, { useState, useEffect } from 'react';
import { Paper, Typography, Box, Button } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';

export const Thankyou = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [activeTest, setActiveTest] = useState(null);

  useEffect(() => {
    let _activeTest = localStorage.getItem('activeTest');
    if (!_activeTest) return;
    _activeTest = JSON.parse(_activeTest);
    if (params['vendor'] == _activeTest['vendor']) {
      setActiveTest(_activeTest);
    }
  }, [params]);

  let onClick = function () {
    let playerId = activeTest.player;
    navigate(`/?player_id=${playerId}`);
  };

  return (
    <Box mt={2}>
      <Paper>
        <Box p={2}>
          <Typography>
            Thankyou for taking the test. Go back to home page.
          </Typography>
        </Box>
      </Paper>
      {activeTest && (
        <Box display='flex' alignItems='flex-end' flexDirection='column' mt={2}>
          <Button variant='contained' onClick={onClick}>
            {' '}
            Back to home{' '}
          </Button>
        </Box>
      )}
    </Box>
  );
};
