import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

const DisabledCookieDialog = () => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      fullScreen={fullScreen}
      open={true}
      aria-labelledby='responsive-dialog-title'>
      <DialogTitle id='responsive-dialog-title'>
        {'Cookies Disabled'}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please enable cookies from your browser settings to continue using the
          app.
        </DialogContentText>
        <DialogContentText marginTop={1}>
          {`Go to 'Settings' -{'>'} Site Settings -{'>'} Cookies -{'>'} Allow all cookies`}
        </DialogContentText>
        <DialogContentText marginTop={1}>
          {`Please click on "Refresh" after making the change.`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => location.reload()}>Refresh</Button>
      </DialogActions>
    </Dialog>
  );
};

export default DisabledCookieDialog;
