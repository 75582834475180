import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import LoadingButton from '@mui/lab/LoadingButton';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import { ReactComponent as SquadStackPartnersLogo } from '../../assets/squadStackPartnersLogo-full-dark.svg';
import { Carousel } from '../../components/Carousel';

function PhoneNumberLoginView({
  phoneNumber,
  isSubmitting,
  error,
  handlePhoneNumberInput,
  onSendOTP,
  isSendOtpDisabled,
}) {
  return (
    <Grid
      container
      direction='row'
      justifyContent='center'
      alignItems='center'
      height='100vh'
      overflow='hidden'>
      <Grid
        item
        lg={6}
        xl={6}
        sx={{
          display: {
            xs: 'none',
            sm: 'none',
            md: 'none',
            lg: 'block',
            xl: 'block',
          },
        }}>
        <Carousel />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
        <Box>
          <Box
            display={'flex'}
            flexDirection='column'
            justifyContent='center'
            height='100vh'
            paddingX={{ xs: 4, sm: 20, md: 30, lg: 15, xl: 25 }}
            paddingY={5}
            sx={{
              bgcolor: 'common.white',
              alignItems: {
                xs: 'center',
                sm: 'center',
                md: 'center',
                lg: 'flex-start',
                xl: 'flex-start',
              },
            }}>
            <Box display={'flex'} height='48px' pr={1}>
              <SquadStackPartnersLogo />
            </Box>
            <Typography
              variant='h6'
              sx={{
                fontWeight: '600',
                fontSize: 24,
                lineHeight: '32px',
                color: 'text.primary',
                marginTop: 4,
              }}>
              Get Started with SquadStack
            </Typography>
            <Box sx={{ marginTop: 2 }} width='100%'>
              <form onSubmit={onSendOTP}>
                <InputLabel>Phone number</InputLabel>
                <TextField
                  id='outlined-basic'
                  hiddenLabel
                  label={phoneNumber.length ? '' : '88888 88888'}
                  variant='outlined'
                  fullWidth
                  size='small'
                  value={phoneNumber}
                  onChange={handlePhoneNumberInput}
                  error={Boolean(error)}
                  inputProps={{
                    maxLength: 10,
                    'data-testid': 'phone-number-input',
                  }}
                  InputLabelProps={{ shrink: false }}
                />
              </form>
            </Box>
            {error ? (
              <Typography
                variant='body'
                sx={{
                  color: 'text.error',
                }}
                alignSelf='flex-start'>
                {error}
              </Typography>
            ) : null}
            <LoadingButton
              data-testid='get-started-button'
              fullWidth
              sx={{ marginTop: 4, textTransform: 'none', fontWeight: 600 }}
              variant='contained'
              size='large'
              color='success'
              onClick={onSendOTP}
              loading={isSubmitting}
              disabled={isSendOtpDisabled}>
              Get Started
            </LoadingButton>
            <Box sx={{ marginTop: 1 }}>
              <Typography
                variant='subtitle2'
                sx={{
                  fontSize: 14,
                  color: 'text.secondary',
                  fontWeight: 400,
                }}>
                By signing up, you agree to the&nbsp;
                <Link
                  href='https://www.squadrunapp.com/terms-condition'
                  color='tint.main'
                  variant='subtitle2'
                  underline='none'
                  target='_blank'
                  rel='noopener noreferrer'
                  fontWeight={600}>
                  terms and conditions
                </Link>
                &nbsp;and&nbsp;
                <Link
                  href='https://www.squadrunapp.com/privacy-policy'
                  color='tint.main'
                  variant='subtitle2'
                  underline='none'
                  target='_blank'
                  rel='noopener noreferrer'
                  fontWeight={600}>
                  privacy policy
                </Link>
              </Typography>
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}

export default PhoneNumberLoginView;
