import React, { useEffect } from 'react';
import emptyBadge from '../../assets/emptyBadge.png';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Logger } from '../../services';

const EmptyWorkflow = () => {
  useEffect(() => {
    Logger.info('Empty Workflow Mounted');

    () => {
      Logger.info('Empty Workflow Unmounted');
    };
  }, []);

  return (
    <Box
      display='flex'
      flexDirection='column'
      alignItems='center'
      justifyContent='space-between'
      padding={2}
      paddingTop='40px'>
      <Typography
        sx={{
          fontWeight: '700',
          fontSize: 24,
          lineHeight: '32px',
          color: 'text.primary',
          textAlign: 'center',
        }}>
        Welcome to Squadstack
      </Typography>
      <img
        src={emptyBadge}
        style={{ maxWidth: '143px', minWidth: '143px', marginTop: '32px' }}
      />
      <Typography
        variant='subtitle2'
        sx={{
          fontSize: 20,
          lineHeight: '32px',
          color: 'text.primary',
          textAlign: 'center',
          marginTop: 2,
          maxWidth: 270,
          fontWeight: 400,
        }}>
        The calls you make for this task will appear here
      </Typography>
    </Box>
  );
};

export default EmptyWorkflow;
