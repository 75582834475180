import React, {
  useLayoutEffect,
  useState,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import {
  Thankyou,
  Home,
  OTPVerification,
  Workflow,
  PhoneNumberLogin,
  GetStarted,
  Redirect,
} from './screens';
import {
  NavbarVariants,
  Navbar,
  IOSDeviceDialog,
  DisabledAppDialog,
  DisabledCookieDialog,
} from './components';
import Box from '@mui/material/Box';
import {
  BrowserRouter,
  Route,
  Routes,
  useNavigate,
  Navigate,
  useLocation,
} from 'react-router-dom';
import { Logger, Support } from './services';
import { STORAGE_KEYS, detectBrowser } from './constants';
import { useQueryParams } from './hooks/useQueryParams';

const Screens = {
  THANKYOU_PAGE: 'THAKYOU_PAGE',
  TRAINING: 'TRAINING',
  WORKFLOW: 'WORKFLOW',
  OTP_VERIFY: 'OTP VERIFY',
  FB_LOGIN: 'FB LOGIN',
  PHONE_LOGIN: 'PHONE LOGIN',
  LOGIN: 'LOGIN',
  GET_STARTED: 'GET STARTED',
  REDIRECT: 'REDIRECT',
};

const screenToPropsMap = {
  [Screens.REDIRECT]: {
    id: Screens.REDIRECT,
    route: '/redirect',
    isProtected: false,
    component: <Redirect />,
  },
  [Screens.TRAINING]: {
    id: Screens.TRAINING,
    route: '/training',
    navbarVariant: NavbarVariants.TRAINING,
    component: <Home />,
    isProtected: true,
  },
  [Screens.THANKYOU_PAGE]: {
    id: Screens.THANKYOU_PAGE,
    route: '/thankyou/:vendor',
    navbarVariant: NavbarVariants.TRAINING,
    component: <Thankyou />,
    isProtected: true,
  },
  [Screens.OTP_VERIFY]: {
    id: Screens.OTP_VERIFY,
    route: '/verify',
    navbarVariant: null,
    redirectIfAuthenticated: true,
    iosCheck: true,
    browserAlert: true,
    component: <OTPVerification />,
  },
  [Screens.LOGIN]: {
    id: Screens.LOGIN,
    route: '/auth',
    navbarVariant: null,
    redirectIfAuthenticated: true,
    iosCheck: true,
    browserAlert: true,
    component: <PhoneNumberLogin />,
  },
  [Screens.GET_STARTED]: {
    id: Screens.GET_STARTED,
    route: '/get-started',
    navbarVariant: null,
    isProtected: true,
    browserAlert: true,
    component: <GetStarted />,
  },
  [Screens.WORKFLOW]: {
    id: Screens.WORKFLOW,
    route: '/workflow',
    navbarVariant: NavbarVariants.SCREENING,
    isProtected: true,
    browserAlert: true,
    component: <Workflow />,
  },
};

const ScreenRenderer = ({
  component,
  navbarVariant,
  isProtected,
  iosCheck,
  redirectIfAuthenticated,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useQueryParams();

  useEffect(() => {
    let userDetails = JSON.parse(
      localStorage.getItem(STORAGE_KEYS.USER_DETAILS)
    );

    const auth = localStorage.getItem(STORAGE_KEYS.AUTH);

    //If token exists but user details are not present, clear the local storage.
    //This is handle a scenario where user manually clears the user details from the storage.
    if (!userDetails && auth) {
      localStorage.clear();
    }

    const isOldUser = Boolean(
      userDetails?.firstName && userDetails?.lastName && userDetails?.email
    );

    if (isProtected && !auth) {
      navigate(`/auth?${searchParams.toString()}`);
    } else if (isProtected && auth && !isOldUser) {
      //When userdetails are not present in the local storage and take the user to get-started page
      navigate('/get-started');
    } else if (
      isOldUser &&
      location.pathname === screenToPropsMap[Screens.GET_STARTED].route
    ) {
      //When userdetails are present in the local storage and user is on get started page, redirect to workflow
      navigate(`/workflow?${searchParams.toString()}`);
    }

    if (redirectIfAuthenticated && auth) {
      navigate(`/workflow?${searchParams.toString()}`);
    }
  }, [
    isProtected,
    location.pathname,
    navigate,
    redirectIfAuthenticated,
    searchParams,
  ]);

  const currentLocationRef = useRef(null);

  useEffect(() => {
    if (location.pathname !== currentLocationRef.current) {
      Logger.info('Page Load', {
        url: location.pathname,
      });
      currentLocationRef.current = location.pathname;

      // To close support dialog when route is not workflow
      if (location.pathname !== screenToPropsMap[Screens.WORKFLOW].route) {
        Support.close();
      }

      // To handle page refresh scenario on workflow route in which visitor info gets reset
      if (
        localStorage.getItem(STORAGE_KEYS.AUTH) &&
        location.pathname === screenToPropsMap[Screens.WORKFLOW].route
      ) {
        let userDetails = localStorage.getItem(STORAGE_KEYS.USER_DETAILS);
        if (userDetails) {
          const parsedUserDetails = JSON.parse(userDetails);
          Support.updateUserInfo(parsedUserDetails);
          Logger.setUser(parsedUserDetails);
        }
      }
    }
  }, [location]);

  const navbarRef = useRef();
  const [navHeight, setNavHeight] = useState(0);

  useLayoutEffect(() => {
    if (navbarRef.current) {
      setNavHeight(navbarRef.current.offsetHeight);
    }
  }, [location]);

  const componentwithprops = React.cloneElement(component, { navHeight });

  return (
    <Box height='100vh' width='100%'>
      {navbarVariant ? (
        <Navbar ref={navbarRef} variant={navbarVariant} />
      ) : null}
      <Box
        height='100%'
        width='100%'
        paddingTop={navbarVariant ? `${navHeight}px` : 0}>
        {iosCheck ? <IOSDeviceDialog /> : null}
        {componentwithprops}
      </Box>
    </Box>
  );
};

export default function Router() {
  const localStorageDisabled = useMemo(() => {
    try {
      localStorage.setItem(STORAGE_KEYS.TEST, 'test');
      localStorage.removeItem(STORAGE_KEYS.TEST);
      return false;
    } catch {
      return true;
    }
  }, []);

  if (localStorageDisabled || !navigator.cookieEnabled) {
    return <DisabledCookieDialog />;
  }

  if (detectBrowser() !== 'chrome') {
    return <DisabledAppDialog />;
  }
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Navigate to='/workflow' />} />
        {Object.values(screenToPropsMap).map((screen) => (
          <Route
            key={screen.id}
            path={screen.route}
            element={
              <ScreenRenderer
                component={screen.component}
                navbarVariant={screen.navbarVariant}
                isProtected={screen.isProtected}
                redirectIfAuthenticated={screen.redirectIfAuthenticated}
                iosCheck={screen.iosCheck}
                browserAlert={screen.browserAlert}
              />
            }
          />
        ))}
        <Route path='*' element={<Navigate to='/' />} />
      </Routes>
    </BrowserRouter>
  );
}
