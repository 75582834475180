import { useState, useCallback } from 'react';

export const useNetworkSpeed = (minSpeed = 5) => {
  const [toastState, setToastState] = useState({
    isVisible: false,
    msg: '',
    severity: 'success',
    autoHideDuration: null,
    isPersistent: false,
  });

  const onClose = () => {
    setToastState({
      ...toastState,
      isVisible: false,
    });
  };

  const checkNetworkSpeed = useCallback(() => {
    const navigator = window.navigator;
    if (navigator.connection) {
      const connection = navigator.connection;
      if (connection.downlink && connection.downlink < minSpeed) {
        setToastState((toastState) => ({
          ...toastState,
          isVisible: true,
          msg: 'Internet speed is slow. Some features might not work as expected.',
          severity: 'warning',
        }));
      } else {
        setToastState((toastState) => ({
          ...toastState,
          isVisible: false,
          msg: '',
          severity: 'success',
        }));
      }
    }
  }, [minSpeed]);

  return { toastState, checkNetworkSpeed, onClose };
};
