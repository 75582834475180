import React, { useCallback, useState } from 'react';
import { fireNetworkRequest, useAuthenticatedMutation } from '../../network';
import GetStartedView from './GetStartedView';
import { STORAGE_KEYS, validateEmail, validateName } from '../../constants';
import { PLAYER_UPDATE_DETAILS_ERROR_CODES } from './constants';
import { Logger } from '../../services';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

const defaultErrorState = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
};

function GetStartedContainer() {
  const [error, setError] = useState(defaultErrorState);

  const id = JSON.parse(
    localStorage.getItem(STORAGE_KEYS.USER_DETAILS)
  )?.userId;

  const [userDetails, setUserDetails] = useState(() => {
    const usrDetails = JSON.parse(
      localStorage.getItem(STORAGE_KEYS.USER_DETAILS)
    );

    const tempUsrDetails = JSON.parse(
      localStorage.getItem(STORAGE_KEYS.TEMPORARY_USER_DETAILS)
    );

    const tempUsr =
      usrDetails?.phoneNumber.includes(tempUsrDetails?.phoneNumber) ||
      usrDetails?.phoneNumber === tempUsrDetails?.phoneNumber
        ? tempUsrDetails
        : {};

    return {
      firstName: usrDetails?.firstName || tempUsr?.firstName || '',
      lastName: usrDetails?.lastName || tempUsr?.lastName || '',
      email: usrDetails?.email || tempUsr?.email || '',
      phoneNumber: usrDetails?.phoneNumber || '',
    };
  });

  const handleInputChange = (e) => {
    setError({ ...error, [e.target.id]: '' });
    setUserDetails({ ...userDetails, [e.target.id]: e.target.value.trim() });
  };

  const navigate = useNavigate();

  const { mutate, isLoading } = useAuthenticatedMutation(
    ({ user }) => {
      return fireNetworkRequest(
        {
          url: `/players/api/web/v11/player/${id}/`,
          method: 'patch',
          data: {
            user: {
              first_name: user?.first_name,
              last_name: user?.last_name,
              email: user?.email,
            },
          },
        },
        { isAuthenticated: true }
      );
    },
    {
      onSuccess: (data) => {
        const usrDetails = JSON.parse(
          localStorage.getItem(STORAGE_KEYS.USER_DETAILS)
        );
        const updateUserDetails = {
          ...usrDetails,
          firstName: data?.data.user?.first_name,
          lastName: data?.data.user?.last_name,
          email: data?.data.user?.email,
        };
        localStorage.setItem(
          STORAGE_KEYS.USER_DETAILS,
          JSON.stringify(updateUserDetails)
        );
        localStorage.removeItem(STORAGE_KEYS.TEMPORARY_USER_DETAILS);
        navigate('/workflow');
      },

      onError: (e) => {
        if (
          e?.response?.data?.message_code ===
          PLAYER_UPDATE_DETAILS_ERROR_CODES.EMAIL_ALREADY_REGISTERED_ERROR_CODE
        ) {
          setError({ ...error, email: e?.response?.data?.reason });
        }
        Logger.error(
          new Error('Error occurred while updating the user-details'),
          e
        );
        toast.error(e?.response?.data?.error_display_message);
      },
    }
  );

  const hasValidResponses = useCallback(() => {
    let hasError = false;

    if (
      !userDetails.firstName ||
      !userDetails.lastName ||
      !userDetails.email ||
      !validateEmail(userDetails.email) ||
      !validateName(userDetails.firstName) ||
      !validateName(userDetails.lastName)
    ) {
      hasError = true;
      let err = {
        firstName: userDetails.firstName ? '' : 'This field is required',
        lastName: userDetails.lastName ? '' : 'This field is required',
        email: userDetails.email ? '' : 'This field is required',
      };

      if (userDetails.email && !validateEmail(userDetails.email)) {
        err = {
          ...err,
          email: 'Please enter a valid email',
        };
      }

      if (userDetails.firstName && !validateName(userDetails.firstName)) {
        err = {
          ...err,
          firstName: !validateName(userDetails.firstName)
            ? 'Please enter a valid name'
            : '',
        };
      }

      if (userDetails.lastName && !validateName(userDetails.lastName)) {
        err = {
          ...err,
          lastName: !validateName(userDetails.lastName)
            ? 'Please enter a valid name'
            : '',
        };
      }

      setError(err);
      return hasError;
    } else {
      hasError = false;
      setError(defaultErrorState);
      return hasError;
    }
  }, [userDetails.email, userDetails.firstName, userDetails.lastName]);

  const handleContinue = useCallback(() => {
    if (!hasValidResponses()) {
      mutate({
        user: {
          first_name: userDetails?.firstName,
          last_name: userDetails?.lastName,
          email: userDetails?.email,
        },
      });
    }
  }, [
    hasValidResponses,
    mutate,
    userDetails.email,
    userDetails.firstName,
    userDetails.lastName,
  ]);

  return (
    <GetStartedView
      onContinue={handleContinue}
      isLoading={isLoading}
      input={userDetails}
      handleInputChange={handleInputChange}
      error={error}
    />
  );
}

export default GetStartedContainer;
