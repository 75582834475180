import get from 'lodash.get';

export const HttpMethods = {
  GET: 'get',
  POST: 'post',
  PUT: 'put',
  PATCH: 'patch',
  DELETE: 'delete',
};
export class HttpError {
  name = 'HttpError';
  request;
  data;
  status;
  message;

  constructor(request, response) {
    this.request = request;
    this.data = get(response, 'data');
    this.status = get(response, 'status');
    this.message =
      get(response, 'data.error_message') || get(response, 'data.detail');
  }

  serialize() {
    return {
      data: JSON.stringify(this.data),
      status: this.status,
    };
  }

  transformForLogging() {
    return {
      request: this.request.transformForLogging(),
      response: this.serialize(),
    };
  }
}
