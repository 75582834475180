const metabaseUrl =
  'https://metabase.squadplatform.com/question/4298-se-cs-level-info';

const ZOHO_VISITOR_AGENT = process.env.REACT_APP_ZOHO_VISITOR_AGENT;

class SupportService {
  static #enable = false;

  static init(config) {
    this.#enable = config.enable;
  }

  static setUserInfo(userInfo) {
    if (this.#enable) {
      if (window.$zoho && window.$zoho.salesiq) {
        window.$zoho.salesiq.visitor.id(
          userInfo?.userId?.toString() || userInfo?.id?.toString()
        );
        window.$zoho.salesiq.visitor.contactnumber(userInfo?.phoneNumber);
        window.$zoho.salesiq.visitor.info({
          'Visitor agent': ZOHO_VISITOR_AGENT,
          'Player Id': `${userInfo?.userId || userInfo?.id}`,
          'Metabase URL': `${metabaseUrl}?player_id=${
            userInfo?.userId || userInfo?.id
          }`,
          reference_id: userInfo?.referenceId || '',
          'Reference Id': userInfo?.referenceId || '',
        });
      }
    }
  }

  static updateUserInfo(userInfo) {
    if (this.#enable) {
      if (
        window.$zoho &&
        window.$zoho.salesiq &&
        window.$zoho.salesiq.visitor
      ) {
        const valuesObject = window.$zoho.salesiq.values;
        const isVisitorCustomInfoSet = Object.prototype.hasOwnProperty.call(
          valuesObject,
          'info'
        );
        const isVisitorIdSet = Object.prototype.hasOwnProperty.call(
          valuesObject,
          'id'
        );

        if (!isVisitorCustomInfoSet || !isVisitorIdSet) {
          window.$zoho.salesiq.visitor.id(
            userInfo?.userId?.toString() || userInfo?.id?.toString()
          );
          window.$zoho.salesiq.visitor.contactnumber(userInfo?.phoneNumber);
          window.$zoho.salesiq.visitor.info({
            'Visitor agent': ZOHO_VISITOR_AGENT,
            'Player Id': `${userInfo?.userId || userInfo?.id}`,
            'Metabase URL': `${metabaseUrl}?player_id=${
              userInfo?.userId || userInfo?.id
            }`,
            reference_id: userInfo?.referenceId || '',
            'Reference Id': userInfo?.referenceId || '',
          });
        }
      }
    }
  }

  static open() {
    if (this.#enable) {
      if (window.$zoho && window.$zoho.salesiq) {
        window.$zoho.salesiq.floatwindow?.visible('show');
      }
    }
  }

  static close() {
    if (this.#enable) {
      if (window.$zoho && window.$zoho.salesiq) {
        window.$zoho.salesiq.floatwindow?.visible('hide');
      }
    }
  }

  static reset() {
    if (this.#enable) {
      if (window.$zoho && window.$zoho.salesiq) {
        window.$zoho.salesiq?.reset();
      }
    }
  }
}

export default SupportService;
