import React, { useEffect, useState, useMemo } from 'react';
import PhoneNumberLoginView from './PhoneNumberLoginView';
import { useNavigate, useLocation } from 'react-router-dom';
import { fireNetworkRequest, useMutation, useQuery } from '../../network';
import { DataLoading } from '../Workflow';
import Box from '@mui/material/Box';
import { Logger, Support } from '../../services';
import {
  STORAGE_KEYS,
  queryParamsParser,
  validatePhone,
} from '../../constants';
import { useQueryParams } from '../../hooks/useQueryParams';
import { mixpanel } from '../../services';

function PhoneNumberLoginContainer() {
  const location = useLocation();

  const queryParams = useMemo(() => {
    return queryParamsParser(location.search);
  }, [location.search]);

  const {
    source,
    speech_assessment,
    token: accessToken,
    phone: phoneNumberFromUrl,
    firstname: firstNameFromUrl,
    lastname: lastNameFromUrl,
    email: emailFromUrl,
  } = queryParams;

  const [phoneNumber, setPhoneNumber] = useState(() => {
    return phoneNumberFromUrl || '';
  });
  const [error, setError] = useState('');
  const [isSendOtpDisabled, setSendOtpDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const handlePhoneNumberInput = (e) => {
    const phone = e.target.value;
    setError('');
    setPhoneNumber(phone);
    if (phone.length === 10) setSendOtpDisabled(false);
    else setSendOtpDisabled(true);
  };

  const navigate = useNavigate();

  const isSpeechAssessment = speech_assessment === 'true';
  const lead_source = source ? source : '';

  const [searchParams] = useQueryParams();

  const { refetch } = useQuery(
    ['login-with-access-token'],
    () =>
      fireNetworkRequest({
        url: `/players/api/v11/token-login/?source=${lead_source}`,
        method: 'get',
        headers: {
          ['Content-type']: 'application/json; charset=UTF-8',
          ['Authorization']: `Bearer ${accessToken}`,
        },
      }),
    {
      enabled: false,
      onSuccess: (data) => {
        const playerData = data.data?.player;
        const token = data.data?.token;

        const userInfo = {
          userId: playerData?.id,
          firstName: playerData?.first_name,
          lastName: playerData?.last_name,
          email: playerData?.email_id,
          phoneNumber: playerData?.phone_number,
          source: lead_source,
          accessToken: accessToken,
          referenceId: playerData?.reference_id,
        };

        localStorage.setItem(
          STORAGE_KEYS.USER_DETAILS,
          JSON.stringify(userInfo)
        );

        Logger.setUser(userInfo);

        Logger.setGlobalProperties({
          platform: 'android',
          browser: navigator.userAgent,
        });

        Logger.info('Access Token Login Successful', {
          url: `/players/api/v11/token-login/?source=${lead_source}`,
          accessToken: accessToken,
          source: lead_source,
          data: data?.data,
        });
        setIsLoading(false);
        if (isSpeechAssessment) {
          window.location = `https://training.squadrun.co/?player_id=${playerData?.id}`;
        } else {
          Support.setUserInfo(userInfo);
          localStorage.setItem(STORAGE_KEYS.AUTH, token);
          navigate(`/workflow?${searchParams.toString()}`);
        }
      },
      onError: (e) => {
        Logger.error(new Error(`Access Token Login Failed`), {
          e,
          accessToken: accessToken,
          source: lead_source,
        });

        setIsLoading(false);
        window.postMessage('login_failed', '*');

        if (e?.response?.status >= 400 && e?.response?.status < 500) {
          const baseErrorMessage = 'Something went wrong! Please try again.';
          const errorMessage =
            e?.response?.data?.error_display_message || baseErrorMessage;
          setError(errorMessage);
        }
      },
    }
  );

  const { mutate, isLoading: isOtpMutationLoading } = useMutation(
    () =>
      fireNetworkRequest({
        url: '/players/api/v13/otp/',
        method: 'post',
        data: {
          phone_number: `+91${phoneNumber}`,
        },
      }),
    {
      onSuccess: () => {
        mixpanel.trackEvent('User Logged In', {
          phoneNumber,
        });
        mixpanel.disableModule();

        Logger.info('OTP Sent Successfully', {
          phoneNumber: phoneNumber,
          source: source,
        });
        navigate(`/verify?${searchParams.toString()}`, {
          state: { phoneNumber, source, speech_assessment },
        });
      },
      onError: (e) => {
        Logger.error(new Error('OTP Send Failed'), {
          phoneNumber: phoneNumber,
          source: source,
          e,
        });
        if (e?.response?.status >= 400 && e?.response?.status < 500) {
          setError(e?.response?.data?.error_display_message);
        }
      },
    }
  );

  useEffect(() => {
    if (
      queryParams &&
      Object.keys(queryParams).length > 0 &&
      queryParams.enableMixpanel === 'true'
    ) {
      mixpanel.initModule();
      if (mixpanel.isInitialised()) {
        mixpanel.trackEvent('User landed', {
          ...queryParams,
        });
      }
    }
  }, [queryParams]);

  useEffect(() => {
    if (accessToken) {
      setIsLoading(true);
      refetch();
    }

    if (phoneNumberFromUrl && validatePhone(phoneNumberFromUrl)) {
      Logger.info('User via Ads', {
        firstName: firstNameFromUrl,
        lastName: lastNameFromUrl,
        email: emailFromUrl,
        phoneNumber: phoneNumberFromUrl,
        source: source,
      });

      localStorage.setItem(
        STORAGE_KEYS.TEMPORARY_USER_DETAILS,
        JSON.stringify({
          firstName: firstNameFromUrl || '',
          lastName: lastNameFromUrl || '',
          email: emailFromUrl || '',
          phoneNumber: phoneNumberFromUrl || '',
        })
      );

      mutate();
    } else if (phoneNumberFromUrl && !validatePhone(phoneNumberFromUrl)) {
      setError('Invalid Phone Number');
    }
  }, [
    accessToken,
    emailFromUrl,
    firstNameFromUrl,
    lastNameFromUrl,
    mutate,
    phoneNumberFromUrl,
    refetch,
    source,
  ]);

  const onSendOTP = (e) => {
    e?.preventDefault();
    if (isOtpMutationLoading) return;
    const isPhoneNumberValid = validatePhone(phoneNumber);
    setError('');
    if (!isPhoneNumberValid) {
      if (phoneNumber.length !== 10) {
        setError('Please enter phone number of correct length');
      } else {
        setError(`Please enter a valid phone number`);
      }
      return;
    }
    mutate();
    Logger.info('Get Started Button Pressed', {
      phoneNumber: phoneNumber,
      source: source,
    });
  };

  return (
    <>
      {isLoading ? (
        <Box paddingTop={16}>
          <DataLoading />
        </Box>
      ) : (
        <PhoneNumberLoginView
          phoneNumber={phoneNumber}
          isSubmitting={isOtpMutationLoading}
          error={error}
          handlePhoneNumberInput={handlePhoneNumberInput}
          onSendOTP={onSendOTP}
          isSendOtpDisabled={isSendOtpDisabled}
        />
      )}
    </>
  );
}

export default PhoneNumberLoginContainer;
