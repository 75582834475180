import { DatadogLoggerService } from './datadog';

class LoggerService {
  modules = [];

  init(config) {
    if (config.datadog && config.datadog.enable) {
      this.modules.push(new DatadogLoggerService(config.datadog));
    }
  }

  modulesToProcess(options) {
    let modules = this.modules;
    if (!options) {
      return modules;
    }
    if (options.includes) {
      modules = modules.filter((m) => options.includes?.includes(m.name));
    }
    if (options.excludes) {
      modules = modules.filter((m) => !options.excludes?.includes(m.name));
    }
    return modules;
  }

  error(exception, extra, options) {
    this.modulesToProcess(options).forEach((module) => {
      module.error(exception, extra);
    });
  }

  info(message, extra, options) {
    this.modulesToProcess(options).forEach((module) => {
      module.info(message, extra);
    });
  }

  warning(message, extra, options) {
    this.modulesToProcess(options).forEach((module) => {
      module.warning(message, extra);
    });
  }

  setUser(userDetails, options) {
    this.modulesToProcess(options).forEach((module) => {
      module.setUser(userDetails);
    });
  }

  setGlobalProperties(properties, options) {
    this.modulesToProcess(options).forEach((module) => {
      module.setGlobalProperties(properties);
    });
  }

  clearUser(options) {
    this.modulesToProcess(options).forEach((module) => {
      module.clearUser();
    });
  }
}

export const Logger = new LoggerService();
