export const validateEmail = (email = '') => {
  const reg = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
  let isValidEmail = false;
  isValidEmail = reg.test(email);
  return isValidEmail;
};

export const validateName = (name) => {
  const reg = /^[a-zA-Z-,]+(\s{0,1}[a-zA-Z-, ])*$/;
  return reg.test(name) && name !== ' ';
};

export const validatePhone = (phone) => {
  const reg = /[5-9]{1}\d{9}/;
  return reg.test(phone) && phone.length === 10;
};

export const queryParamsParser = (urlParam) => {
  const url = decodeURIComponent(urlParam);
  const regex = /[?&]([^=#]+)=([^&#]*)/g,
    params = {};

  let match;
  while ((match = regex.exec(url))) {
    params[match[1]] = match[2];
  }

  return params;
};
