export const STORAGE_KEYS = {
  USER_DETAILS: 'usr_details',
  AUTH: 'auth',
  IOS_DIALOG: 'ios-dialog',
  TEST: 'test',
  LAST_STAGE_SUBMITTED: 'l_stage_sub',
  TEMPORARY_USER_DETAILS: 't_usr_details',
  COUNT: 'count',
  ASSESSMENT_NAME: 'assessment_name',
};
