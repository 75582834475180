import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

export const Toast = ({
  isVisible,
  autoHideDuration = null,
  severity = 'info',
  msg,
  vertical = 'top',
  horizontal = 'center',
  onClose = () => null,
  isPersistent = false,
}) => (<Snackbar
    anchorOrigin={{ vertical, horizontal }}
    open={isVisible}
    autoHideDuration={autoHideDuration}
    onClose={onClose}>
    <Alert
      onClose={isPersistent ? null : onClose}
      severity={severity}
      sx={{ width: '100%' }}>
      {msg}
    </Alert>
  </Snackbar>
);
