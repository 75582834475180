import React, { useEffect, useState } from 'react';
import {
  Grid,
  Paper,
  Typography,
  Box,
  Chip,
  Button,
  CircularProgress,
  Alert,
} from '@mui/material';
import axios from 'axios';
import { local } from '../../constants';

const enrollPlayer = function (playerId, callback) {
  if (localStorage.getItem('enrolling') == 'true') {
    return;
  }
  localStorage.setItem('enrolling', 'true');
  axios
    .post(local.baseUrl + `/assessments/api/v10/enroll-student`, {
      player_id: playerId,
    })
    .then(() => {
      localStorage.setItem('enrolling', 'false');
      callback(null, playerId);
    })
    .catch((e) => {
      localStorage.setItem('enrolling', 'false');
      callback(e);
    });
};
const fetchAllAssignments = function (playerId, callback) {
  let request = axios.get(
    local.baseUrl + `/assessments/api/v10?player=${playerId}`
  );
  request
    .then((response) => {
      callback(null, response.data);
    })
    .catch((e) => {
      callback(e);
    });
};

export const Home = function () {
  const [assessments, setAssessments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    let playerId = 111;
    if (!playerId) {
      setError('User not set. Please check the url and try again');
      return;
    }
    setLoading(true);
    enrollPlayer(playerId, (err, playerId) => {
      if (err) {
        setLoading(false);
        setError(
          'Error occured while enrolling into the tests. Please contact support '
        );
        return;
      }
      fetchAllAssignments(playerId, (err, assessments) => {
        if (err) {
          setError('Error occured while fetching list of tests ');
        }
        setAssessments(assessments);
        setLoading(false);
      });
    });
  }, []);

  const redirectToTestPage = function (assessment) {
    return () => {
      localStorage.setItem('activeTest', JSON.stringify(assessment));
      window.open(assessment.candidate_test_link);
    };
  };

  let showButton = function (assessment) {
    if (['not_attempted', 'in_progress'].includes(assessment.test_status)) {
      return true;
    } else {
      return false;
    }
  };

  if (loading) {
    return (
      <Box
        display={'flex'}
        justifyContent='center'
        alignItems='center'
        height='400px'>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Alert severity='error' variant='filled'>
        {' '}
        {error}{' '}
      </Alert>
    );
  }

  return (
    <Grid container justifyContent={'center'} paddingTop='32px'>
      <Grid item xs={12} md={6}>
        <Box>
          <Typography variant='h6'>Assessments</Typography>
        </Box>

        {assessments.map((assessment, index) => {
          return (
            <Box paddingTop={2} key={index}>
              <Paper>
                <Box
                  p={2}
                  display='flex'
                  justifyContent={'space-between'}
                  alignItems='center'>
                  <Typography>
                    {local.vendorToString[assessment.vendor]}
                  </Typography>
                  <Chip
                    size='small'
                    variant='outlined'
                    label={local.statusToString[assessment.test_status]}
                    color={local.statusToColor[assessment.test_status]}
                  />
                </Box>
                <Box
                  p={2}
                  paddingTop={1}
                  display='flex'
                  justifyContent='flex-end'
                  alignItems={'center'}>
                  {showButton(assessment) && (
                    <Button
                      variant='contained'
                      color='secondary'
                      onClick={redirectToTestPage(assessment)}
                      style={{ color: 'white' }}>
                      {' '}
                      Take Test{' '}
                    </Button>
                  )}

                  {!showButton(assessment) && assessment.result && (
                    <Typography> Score: {assessment.result} </Typography>
                  )}
                </Box>
              </Paper>
            </Box>
          );
        })}
      </Grid>
    </Grid>
  );
};
