import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import LoadingButton from '@mui/lab/LoadingButton';
import InputLabel from '@mui/material/InputLabel';
import { ReactComponent as SquadStackPartnersLogo } from '../../assets/squadStackPartnersLogo-full-dark.svg';
import { Carousel } from '../../components/Carousel';

const GetStartedView = ({
  onContinue,
  isLoading,
  input,
  handleInputChange,
  error,
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        height: '100vh',
        width: '100vw',
      }}>
      <Box
        sx={{
          display: {
            xs: 'none',
            sm: 'none',
            md: 'none',
            lg: 'block',
            xl: 'block',
          },
          width: '50%',
          height: '100%',
          overflow: 'hidden',
        }}>
        <Carousel />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          width: {
            xs: '100%',
            sm: '100%',
            md: '100%',
            lg: '50%',
            xl: '50%',
          },
          backgroundColor: 'white',
        }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            overflowY: 'scroll',
          }}
          paddingY={5}
          paddingX={{ xs: 4, sm: 20, md: 30, lg: 15, xl: 25 }}>
          <Box display='flex' height='48px' pr={1}>
            <SquadStackPartnersLogo />
          </Box>
          <Typography
            variant='h6'
            sx={{
              fontWeight: '600',
              fontSize: 24,
              lineHeight: '32px',
              color: 'text.primary',
              marginTop: 4,
            }}>
            Get Started with SquadStack
          </Typography>
          <Box width='100%'>
            <form onSubmit={onContinue}>
              <Box sx={{ marginTop: 2 }}>
                <InputLabel>Phone number</InputLabel>
                <TextField
                  inputProps={{ 'data-testid': 'phone-number-input' }}
                  disabled
                  id='phone-number'
                  hiddenLabel
                  fullWidth
                  size='small'
                  defaultValue={input.phoneNumber}
                  sx={{
                    border: '1px rgba(0,0,0,0.3) solid',
                    borderRadius: '4px',
                  }}
                />
              </Box>

              <Box sx={{ marginTop: 2 }}>
                <InputLabel>First Name</InputLabel>
                <TextField
                  id='firstName'
                  hiddenLabel
                  variant='outlined'
                  fullWidth
                  size='small'
                  value={input.firstName}
                  onChange={handleInputChange}
                  error={Boolean(error?.firstName)}
                  inputProps={{
                    maxLength: 30,
                    'data-testid': 'first-name-input',
                  }}
                  InputLabelProps={{ shrink: false }}
                />
                {error?.firstName ? (
                  <Typography
                    variant='body'
                    sx={{
                      lineHeight: '32px',
                      color: 'text.error',
                    }}
                    alignSelf='flex-start'>
                    {error?.firstName}
                  </Typography>
                ) : null}
              </Box>

              <Box sx={{ marginTop: 2 }}>
                <InputLabel>Last Name</InputLabel>
                <TextField
                  id='lastName'
                  hiddenLabel
                  variant='outlined'
                  fullWidth
                  size='small'
                  value={input.lastName}
                  onChange={handleInputChange}
                  error={Boolean(error?.lastName)}
                  inputProps={{
                    maxLength: 30,
                    'data-testid': 'last-name-input',
                  }}
                  InputLabelProps={{ shrink: false }}
                />
                {error?.lastName ? (
                  <Typography
                    variant='body'
                    sx={{
                      lineHeight: '32px',
                      color: 'text.error',
                    }}
                    alignSelf='flex-start'>
                    {error?.lastName}
                  </Typography>
                ) : null}
              </Box>

              <Box sx={{ marginTop: 2 }}>
                <InputLabel>Email</InputLabel>
                <TextField
                  id='email'
                  hiddenLabel
                  variant='outlined'
                  fullWidth
                  size='small'
                  value={input.email}
                  onChange={handleInputChange}
                  error={Boolean(error?.email)}
                  InputLabelProps={{ shrink: false }}
                  inputProps={{
                    maxLength: 30,
                    'data-testid': 'email-input',
                  }}
                />
                {error?.email ? (
                  <Typography
                    variant='body'
                    sx={{
                      lineHeight: '32px',
                      color: 'text.error',
                    }}
                    alignSelf='flex-start'>
                    {error?.email}
                  </Typography>
                ) : null}
              </Box>
            </form>
          </Box>
          <LoadingButton
            data-testid='continue-button'
            fullWidth
            sx={{ marginTop: 4, textTransform: 'none', fontWeight: 600 }}
            variant='contained'
            size='large'
            color='success'
            onClick={onContinue}
            loading={isLoading}>
            Continue
          </LoadingButton>
          <Box sx={{ marginTop: 1 }}>
            <Typography
              variant='subtitle2'
              sx={{
                fontSize: 14,
                color: 'text.secondary',
                fontWeight: 400,
              }}>
              By signing up, you agree to the&nbsp;
              <Link
                href='https://www.squadrunapp.com/terms-condition'
                color='tint.main'
                variant='subtitle2'
                underline='none'
                target='_blank'
                rel='noopener noreferrer'
                fontWeight={600}>
                terms and conditions
              </Link>
              &nbsp;and&nbsp;
              <Link
                href='https://www.squadrunapp.com/privacy-policy'
                color='tint.main'
                variant='subtitle2'
                underline='none'
                target='_blank'
                rel='noopener noreferrer'
                fontWeight={600}>
                privacy policy
              </Link>
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default GetStartedView;
