import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  typography: {
    fontFamily: ['Inter', 'Roboto', 'sans-serif'].join(','),
  },
  palette: {
    background: {
      default: '#F5F5F5',
    },
    primary: {
      main: '#162447',
      light: '#6F788D',
    },
    secondary: {
      main: '#39CEA0',
    },
    common: {
      white: '#FFFFFF',
    },
    text: {
      primary: '#323232',
      secondary: '#757575',
      error: '#DF1642',
    },
    success: {
      main: '#39CEA0',
      contrastText: '#FFFFFF',
      success100: '#E2F9F2',
      success700: '#113E30',
      dark: '#39CEAF',
    },
    warning: {
      main: '#FFF4E0',
      warning300: '#FFCE70',
      dark: '#805E1C',
    },
    error: {
      main: '#FADBD8',
      dark: '#8B2E24',
    },
    tint: {
      main: '#2590B8',
      lavender700: '#2A0751',
      lavender600: '#4A0D8E',
    },
    action: {
      disabledBackground: '#E0E0E0',
      disabled: '#FFFFFF',
    },
    disabled: {
      main: '#E0E0E0',
    },
    input: {
      main: '#E0E0E0',
      contrastText: '#FFFFFF',
      dark: '#E0E0E0',
    },
  },
});
