import React, { useState, useMemo } from 'react';
import FacebookLoginView from './FacebookLoginView';
import { useNavigate, useLocation } from 'react-router-dom';
import { fireNetworkRequest, useMutation } from '../../network';
import { mixpanel } from '../../services';
import { STORAGE_KEYS } from '../../constants';

export const validatePhone = (phone) => {
  const reg = /[5-9]{1}\d{9}/;
  return reg.test(phone);
};

const queryParamsParser = (searhQuery = '') => {
  const qParams = {};
  const cleanedQuery = searhQuery.substring(1);
  const splitQuery = cleanedQuery.split('=');
  for (let i = 0; i < splitQuery.length; i = i + 2) {
    qParams[splitQuery[i]] = splitQuery[i + 1];
  }
  return qParams;
};

function FacebookLoginContainer() {
  const [error, setError] = useState('');

  const navigate = useNavigate();

  const location = useLocation();

  const { source } = useMemo(() => {
    return queryParamsParser(location.search);
  }, [location.search]);

  const { mutate, isLoginApiLoading } = useMutation(
    ({ accessToken, source }) =>
      fireNetworkRequest({
        url: '/players/api/tof/fb-login-signup/',
        method: 'post',
        data: {
          auth_token: accessToken,
          source: source || '',
        },
      }),
    {
      onSuccess: (e) => {
        const token = e.data.token;
        localStorage.setItem(STORAGE_KEYS.AUTH, token);

        if (e.data?.player) {
          mixpanel.identify({ ...e.data?.player, source: source });
        }
        mixpanel.trackEvent('FB Login Success', {
          workflow: source,
        });
        navigate('/workflow');
      },
      onError: (e) => {
        mixpanel.trackEvent('FB Login Failed', e);
        if (e.response.status >= 400 && e.response.status < 500) {
          if (!source) {
            setError('Invalid URL, please contact support for assistance!');
          } else {
            setError(e.response.data.error_message);
          }
        }
      },
      retry: 0,
    }
  );

  const onFbLogin = () => {
    setError('');
    if (typeof FB !== 'undefined') {
      FB.login(function (response) {
        if (response.status === 'connected') {
          mutate({ accessToken: response.authResponse.accessToken, source });
          mixpanel.trackEvent('FB Login Clicked');
        } else {
          setError('Please login through facebook to access the platform');
          mixpanel.trackEvent('FB Login Cancelled', response);
        }
      });
    }
  };

  const isLoading = useMemo(
    () => typeof FB === 'undefined' || isLoginApiLoading,
    [isLoginApiLoading]
  );
  const isDisabled = useMemo(() => source === undefined, [source]);

  return (
    <FacebookLoginView
      error={error}
      onFbLogin={onFbLogin}
      isLoading={isLoading}
      isDisabled={isDisabled}
    />
  );
}

export default React.memo(FacebookLoginContainer);
