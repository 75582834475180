import React, { useCallback, useEffect, useRef } from 'react';
import { Box, Button, Typography } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { Logger } from '../../services';
import { STORAGE_KEYS } from '../../constants';

function AssessmentNavbar(props) {
  const { onClose, title, index } = props;
  return (
    <Box
      data-testid='assessment-navbar'
      display='flex'
      flexDirection='row'
      alignItems='center'
      flexGrow={1}
      paddingX={1}
      paddingY={1}
      backgroundColor={'common.white'}
      zIndex={10}>
      <Box
        display='flex'
        alignItems='center'
        sx={{
          cursor: 'pointer',
        }}
        onClick={onClose}>
        <Button
          sx={{
            textTransform: 'none',
            '@media (min-width: 600px)': {
              '&:hover': {
                color: 'secondary.main',
                '& svg': {
                  color: 'secondary.main',
                },
              },
            },
          }}>
          <KeyboardArrowLeftIcon
            sx={{
              color: 'primary.main',
            }}
          />
          <Typography
            sx={{
              display: 'flex',
              flexDirection: 'row',
              fontWeight: '400',
              fontSize: 14,
              color: 'primary.main',
              marginLeft: 1,
            }}>
            <span
              style={{
                fontWeight: '600',
                fontSize: 14,
              }}>
              {`Step ${index}`}
            </span>
            {`: ${title}`}
          </Typography>
        </Button>
      </Box>
    </Box>
  );
}

export const Assessment = ({
  assessmentId,
  src,
  title,
  index,
  handleAssessmentSubmission,
}) => {
  const isAssessmentSubmitted = useRef(false);

  const handleFormSubmission = useCallback(
    (e) => {
      if (
        (e.origin.includes('jotform.co') &&
          e?.data?.action === 'submission-completed') ||
        (e.origin.includes('paperform.co') &&
          typeof e.data === 'string' &&
          e?.data.includes('submission')) ||
        (e.origin.includes('submit.jotform') &&
          e?.data?.action === 'submission-completed')
      ) {
        Logger.info('Form Submission Event Received', {
          origin: e?.origin,
          data: e?.data,
          assessementId: assessmentId,
          source: src,
          assessmentTitle: title,
        });
        setTimeout(() => {
          handleAssessmentSubmission(assessmentId);
        }, 1000);
        isAssessmentSubmitted.current = true;
      }
    },
    [assessmentId, handleAssessmentSubmission, src, title]
  );

  useEffect(() => {
    const preventUnload = (event) => {
      Logger.info('User trying to leave Assessment!', {
        assessementId: assessmentId,
        source: src,
        assessmentTitle: title,
        assessmentIndex: index,
      });

      const count = parseInt(localStorage.getItem(STORAGE_KEYS.COUNT)) || 0;
      localStorage.setItem(STORAGE_KEYS.COUNT, count + 1);
      localStorage.setItem(STORAGE_KEYS.ASSESSMENT_NAME, title);

      // NOTE: This message isn't used in modern browsers, but is required
      const message = 'Sure you want to leave?';
      event.preventDefault();

      if (isAssessmentSubmitted.current === false) {
        event.returnValue = message;
      }
    };

    window.addEventListener('beforeunload', preventUnload);

    window.addEventListener('message', handleFormSubmission);
    return () => {
      window.removeEventListener('beforeunload', preventUnload);
      window.removeEventListener('message', handleFormSubmission);
    };
  }, [assessmentId, handleFormSubmission, index, src, title]);

  const handleClose = () => {
    Logger.info('Assessment Back Button Clicked', {
      assessementId: assessmentId,
      source: src,
      assessmentTitle: title,
      assessmentIndex: index,
    });
    window.open('workflow', '_self', 'noopener,noreferrer');
  };

  return (
    <>
      <Box display='flex' flexDirection='column' flexGrow={1} width='100%'>
        <AssessmentNavbar onClose={handleClose} title={title} index={index} />
        <iframe
          data-testid='assessment-iframe'
          allowFullScreen={true}
          allow='microphone'
          target='_top'
          src={src}
          style={{
            width: '100%',
            height: '100%',
            border: 'none',
          }}
        />
      </Box>
    </>
  );
};
